
import React, { Context, useState } from 'react';

import * as axios from 'axios';

import {
	Avatar,
	Button,
	TextField,
	Paper,
	Box,
	Grid,
	Typography,
	createTheme,
	ThemeProvider,
	Container,
	Stepper,
	StepLabel,
	Step,
	FormControl,
	TextareaAutosize,
} from '@mui/material';

import { Editor, EditorState, RichUtils } from 'draft-js';
import 'draft-js/dist/Draft.css';
 
import { REPORTS } from '../../config/api';

const steps = [
	'Introduction',
	'Table of Contents',
	'Analysis',
	'Summary'
];


const Report = () => {

	const [status, setStatus] = useState(0);
	const [activeStep, setActiveStep] = useState(0);
	const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
	const [analysis, setAnalysis] = useState([
		{ image: '', annotation: '' },
	]);

	const [userData, setUserData] = useState({});

	const collectFormData = (key, val) => {

		setUserData({
			...userData,
			[key]: val
		});

	}

	const handleNext = () => {

		setActiveStep((prevActiveStep) => prevActiveStep + 1);

		if (activeStep === steps.length - 1) {
			createReport();
		}

	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleChange = (i, e) => {

		const newAnalysis = [...analysis];

		newAnalysis[i][e.target.name] = e.target.value;
		setAnalysis(newAnalysis);

	};

	const addAnalysis = () => {
		setAnalysis([...analysis, { image: '', annotation: '' }])
	};

	const removeAnalysis = (i) => {

		let newAnalysis = [...analysis];
		newAnalysis.splice(i, 1);

		setAnalysis(newAnalysis);

	};

	const createReport = () => {

		console.log({
			...userData,
			analysis,
			x: editorState.toJS()
		})

		// if (userData.password === userData.confirmPassword) {

		// 	axios({
		// 		data: userData,
		// 		method: 'POST',
		// 		url: `${REPORTS}/create`,
		// 	})
		// 	.then(response => setStatus(response.status));

		// }

	}

	return (

		<form
			autoComplete='off'>

			<Typography
				variant='h2'
				sx={{
					letterSpacing: '-0.00833em',
					margin: '0px 0px 9px',
					fontWeight: 700,
					fontSize: '30px',
					lineHeight: '1.2'
				}}>
				Create Report
			</Typography>

			<Stepper
				alternativeLabel
				activeStep={activeStep}
				sx={{
					display: 'flex',
					alignItems: 'flex-start',
					paddingTop: '20px',
					paddingBottom: '20px',
					background: 'rgba(34, 51, 84, 0.02)',
				}}>

				{steps.map((label, index) => {

					const stepProps = {};
					const labelProps = {};

					return (

						<Step
							key={label}
							{...stepProps}
							xs={{
								paddingLeft: '8px',
								paddingRight: '8px',
								flex: '1 1 0%',
								position: 'relative',
							}}>

							<StepLabel {...labelProps}>
								{label}
							</StepLabel>

						</Step>

					);

				})}

			</Stepper>

			<Box
				sx={{ padding: '36px' }}>

				<Grid
					container
					spacing={4}
					sx={{
						boxSizing: 'border-box',
						display: 'flex',
						flexFlow: 'row wrap',
						marginTop: '-36px',
						width: 'calc(100% + 36px)',
						marginLeft: '-36px',
						'& .MuiInputLabel-root': {
							fontSize: '14px',
							fontWeight: 400,
							lineHeight: '1.4375em',
							color: 'rgb(34, 51, 84)',
							boxSizing: 'border-box',
							cursor: 'text',
							display: 'inline-flex',
							alignItems: 'center',
							width: '100%',
							borderRadius: '10px'
						},
						'& textarea': {
							fontSize: '14px',
							fontWeight: 400,
							lineHeight: '1.4375em',
							color: 'rgb(34, 51, 84)',
							boxSizing: 'border-box',
							cursor: 'text',
							display: 'inline-flex',
							alignItems: 'center',
							width: '100%',
							borderRadius: '10px'
						}
					}}>

					{activeStep === 0 &&

						<>

							<Grid
								item
								xs={12}
								sx={{
									boxSizing: 'border-box',
									margin: '0px',
									flexDirection: 'row',
									flexBasis: { xs: '100%', md: '50%' },
									flexGrow: 0,
									maxWidth: { xs: '100%', md: '50%' }
								}}>

								<FormControl
									sx={{
										display: 'inline-flex',
										flexDirection: 'column',
										position: 'relative',
										minWidth: '0px',
										padding: '0px',
										margin: '0px',
										border: '0px',
										verticalAlign: 'top',
										width: '100%',
									}}>

									<TextField
										required
										label="Client"
										onChange={(e) => collectFormData('client', e.target.value)}
										fullWidth
										placeholder='Client name, please'
										variant="outlined" />

								</FormControl>

							</Grid>

							<Grid
								item
								xs={12}
								md={6}
								sx={{
									boxSizing: 'border-box',
									margin: '0px',
									flexDirection: 'row',
									flexBasis: { xs: '100%', md: '50%' },
									flexGrow: 0,
									maxWidth: { xs: '100%', md: '50%' }
								}}>
							</Grid>

							<Grid
								item
								xs={12}
								md={12}
								sx={{
									boxSizing: 'border-box',
									margin: '0px',
									flexDirection: 'row',
									flexBasis: { xs: '100%' },
									flexGrow: 0,
									maxWidth: { xs: '100%' },
									borderColor: 'rgb(196 196 196)',
								}}>

								<div style={{
									width: '600px'
								}}>

									<Editor editorState={editorState}
										placeholder="Enter some text..."
										onChange={setEditorState} />

								</div>

								<FormControl
									sx={{
										display: 'inline-flex',
										flexDirection: 'column',
										position: 'relative',
										minWidth: '0px',
										padding: '0px',
										margin: '0px',
										border: '0px',
										verticalAlign: 'top',
									}}>



									{/* <TextareaAutosize
										minRows={15}
										onChange={(e) => collectFormData('intro', e.target.value)}
										placeholder="Report introduction here"
										style={{
											fontFamily: 'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
											padding: '16.5px 14px',
											borderRadius: '5px',
											width: '850px'
										}} /> */}

								</FormControl>

							</Grid>

						</>

					}

					{activeStep === 1 &&

						<>

							<Grid
								item
								xs={12}
								md={12}
								sx={{
									boxSizing: 'border-box',
									margin: '0px',
									flexDirection: 'row',
									flexBasis: { xs: '100%', md: '50%' },
									flexGrow: 0,
									maxWidth: { xs: '100%', md: '50%' }
								}}>

								<FormControl
									sx={{
										display: 'inline-flex',
										flexDirection: 'column',
										position: 'relative',
										minWidth: '0px',
										padding: '0px',
										margin: '0px',
										border: '0px',
										verticalAlign: 'top',
										width: '100%',
									}}>

									<TextareaAutosize
										minRows={15}
										onChange={(e) => collectFormData('summary', e.target.value)}
										placeholder="Table of Contents"
										style={{
											fontFamily: 'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
											padding: '16.5px 14px',
											borderRadius: '5px',
											borderColor: 'rgb(196 196 196)',
											width: '850px'
										}} />

								</FormControl>

							</Grid>

						</>

					}

					{activeStep === 2 &&

						<>

							{analysis.map((element, index) => (

								<div key={index}>

									<Grid
										item
										xs={12}
										md={6}
										sx={{
											boxSizing: 'border-box',
											margin: '0px',
											flexDirection: 'row',
											flexBasis: { xs: '100%', md: '50%' },
											flexGrow: 0,
											maxWidth: { xs: '100%', md: '50%' }
										}}>

										<FormControl
											sx={{
												display: 'inline-flex',
												flexDirection: 'column',
												position: 'relative',
												minWidth: '0px',
												padding: '0px',
												margin: '0px',
												border: '0px',
												verticalAlign: 'top',
												width: '100%',
											}}>

											<TextField
												required
												label="Image"
												onChange={(e) => handleChange(index, e)}
												fullWidth
												name='image'
												placeholder='Image'
												variant="outlined" />

										</FormControl>

									</Grid>

									<Grid
										item
										xs={12}
										md={12}
										sx={{
											boxSizing: 'border-box',
											margin: '0px',
											flexDirection: 'row',
											flexBasis: { xs: '100%' },
											flexGrow: 0,
											maxWidth: { xs: '100%' }
										}}>

										<FormControl
											sx={{
												display: 'inline-flex',
												flexDirection: 'column',
												position: 'relative',
												minWidth: '0px',
												padding: '0px',
												margin: '0px',
												border: '0px',
												verticalAlign: 'top',
												width: '100%',
											}}>

											<TextareaAutosize
												minRows={5}
												name='annotation'
												onChange={(e) => handleChange(index, e)}
												placeholder="Table of Contents"
												style={{
													fontFamily: 'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
													padding: '16.5px 14px',
													borderRadius: '5px',
													borderColor: 'rgb(196 196 196)',
													width: '850px'
												}} />

										</FormControl>

									</Grid>

								</div>

							))}

							<button onClick={addAnalysis}>Add</button>
							<button onClick={removeAnalysis}>Remove</button>

						</>

					}

					{activeStep === 3 &&

						<Grid
							item
							xs={12}
							md={12}
							sx={{
								boxSizing: 'border-box',
								margin: '0px',
								flexDirection: 'row',
								flexBasis: { xs: '100%', md: '50%' },
								flexGrow: 0,
								maxWidth: { xs: '100%', md: '50%' }
							}}>

							<FormControl
								sx={{
									display: 'inline-flex',
									flexDirection: 'column',
									position: 'relative',
									minWidth: '0px',
									padding: '0px',
									margin: '0px',
									border: '0px',
									verticalAlign: 'top',
									width: '100%',
								}}>

								<TextareaAutosize
									minRows={15}
									onChange={(e) => collectFormData('contents', e.target.value)}
									placeholder="Table of Contents"
									style={{
										fontFamily: 'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
										padding: '16.5px 14px',
										borderRadius: '5px',
										borderColor: 'rgb(196 196 196)',
										width: '850px'
									}} />

							</FormControl>

						</Grid>

					}

				</Grid>

			</Box>

			{activeStep <= steps.length &&

				<Box sx={{
					padding: '36px',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
					background: 'rgba(34, 51, 84, 0.02)',
					'& .MuiButtonBase-root.Mui-disabled': {
						color: 'rgba(34, 51, 84, 0.5)',
						border: '1px solid rgba(34, 51, 84, 0.02)',
						background: 'transparent'
					},
					'& .MuiButtonBase-root': {
						display: 'inline-flex',
						alignItems: 'center',
						justifyContent: 'center',
						position: 'relative',
						boxSizing: 'border-box',
						outline: '0px',
						border: '0px',
						margin: '0px',
						cursor: 'pointer',
						userSelect: 'none',
						verticalAlign: 'middle',
						appearance: 'none',
						textDecoration: 'none',
						fontSize: '0.875rem',
						lineHeight: '1.75',
						minWidth: '64px',
						borderRadius: '10px',
						transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
						color: 'rgb(255, 255, 255)',
						backgroundColor: 'rgb(36, 66, 175)',
						boxShadow: 'none',
						fontWeight: 'bold',
						textTransform: 'none',
						padding: '8px 20px',
						'&:hover': {
							color: 'rgb(255, 255, 255)',
							backgroundColor: 'rgb(36, 66, 175)',
						}
					}
				}}>

					<Button
						color="inherit"
						disabled={activeStep === 0}
						onClick={handleBack}
						sx={{ mr: 1 }}>
						Previous
					</Button>

					<Button onClick={handleNext}>
						{activeStep === steps.length - 1 ? 'Submit' : 'Next step'}
					</Button>

				</Box>

			}

		</form>

	);

}

export default Report;
