import React, {
	useState,
	useEffect,
	useContext
} from 'react';

import {
	useNavigate
} from 'react-router-dom';

import {
	Box,
	IconButton,
	Typography,
	TableCell,
	TableRow,
	Chip,
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';

import { AUTH, } from '../config/api';
import Footer from '../components/footer';
import ModalComponent from '../components/common/global-component/modal/modal-component';
import DialogComponent from '../components/common/global-component/dialog/dialog-component';
import ModalService from '../services/modal-service';
import { AppPermissionsContext } from '../components/common/app-context/app-permission-context';
import { getUsers, getClients, getRoles } from '../services/api-services';
import TableContent from '../components/common/pages/table-content-components';

const roleColours = {
	user: {
		background: 'rgba(51, 194, 255, 0.1)',
		color: 'rgb(51, 194, 255)'
	},
	admin: {
		background: 'rgba(255, 163, 25, 0.1)',
		color: 'rgb(255, 163, 25)'
	},
	super: {
		background: 'rgba(255, 25, 67, 0.1)',
		color: 'rgb(255, 25, 67)'
	}
}

const screen = {
	type: 'Users',
	heading: {
		title: 'Users Management',
		description: 'All aspects related to users can be managed from this page.'
	},
	search: {
		type:'text',
		placeholder:'Search by name, email or username',
		fullWidth:true,
		size:'small'
	},
	tableHead: [
		'Name',
		'Surname',
		'Email',
		'Company',
		'Role',
		''
	]
}

const Users = ({
    api, 
}) => {
	const { storedUser, permissions } = useContext(AppPermissionsContext);
    const [objects, setObjects] = useState([]);

	const [clients, setClients] = useState([]);
	const [roles, setRoles] = useState([]);
	const [loaded, setLoaded] = useState(false);

	const navigate = useNavigate();

	const formControls = [
		{
			label: "Name",
			placeholder: "Name",
			helperText: "Name is required",
			required: true,
			validation: (obj) => true
		},
		{
			label: "Surname",
			placeholder: "Surname",
			helperText: "Surname is required",
			required: true,
			validation: (obj) => true
		},
		{
			label: "Email",
			placeholder: "Email",
			helperText: "Email is required",
			required: true,
			validation: (obj) => true
		},
		{
			label: "Number",
			placeholder: "Phone Number",
			helperText: "Phone Number is required",
			required: true,
			validation: (obj) => true
		},
		{
			label: "Company",
			placeholder: "",
			type: 'selectbox',
			options: clients,
			name: "client_id",
			required:true,
		},
		{
			label: "User Role",
			placeholder: "",
			type: 'selectbox',
			options: roles,
			name: "role_id",
			required: true,
		},
		{
			label: "Password",
			placeholder: "",
			type: 'password',
			helperText: "Password is required",
			required: true,
			validation: (obj) => obj
		},
		{
			label: "Confirm Password",
			placeholder: "",
			type: 'password',
			name: "confirmPassword",
			helperText: "Confirm Password is required",
			required: true,
			validation: (obj) => obj
		}
	]

	const createModal = {
		form: {
			description: 'Fill in all the fields below to create a new user.',
			formControls,
			processText: 'Add new user',
			title: 'Add New User',
			validation: () => true,
			type: 'create'
		},
		request: {
			dataParam: {
				tandc: true
			},
			method: 'POST',
			requestValidation: () => modals.create.modalData.formData.password === modals.create.modalData.formData.confirmpassword,
			url: () => `${AUTH}/create`,
		}
	}

	const editModal = {
		form: {
			description: 'Fill in all the fields below to create a new client.',
			formControls,
			processText: 'Edit user',
			title: 'Edit Existing User',
			validation: () => true,
			type: 'edit'
		},
		request: {
			method: 'PATCH',
			requestValidation: () => modals.edit.modalData.selected,
			url: () => `${AUTH}/${modals.edit.modalData.selected.id}`,
		}
	}

	const deleteModal = {
		form: {
			description: 'Are you sure, you know what you doing?',
			formControls: [
				{
					label: "Name",
					type: 'readonly',
					disabled: true,
				},
				{
					label: "ID",
					type: 'readonly',
					disabled: true,
				}
			],
			processText: 'Delete User',
			title: 'Delete existing user',
			validation: () => true,
			type: 'delete'
		},
		request: {
			method: 'DELETE',
			requestValidation: () => modals.delete.modalData.selected.id,
			url: () => `${AUTH}/${modals.delete.modalData.selected.id}`,
		}
	}

	const modals = {
		create: ModalService(createModal),
		edit: ModalService(editModal),
		delete: ModalService(deleteModal),
	}

	useEffect(() => {
		Promise.all([
			getClients(storedUser),
			getRoles(storedUser),
			getUsers(storedUser)
		]).then(([
			clients,
			roles,
			users
		]) => {
			setClients(clients.data);
			const rolesFiltered = roles.data.filter(f=> users.data.find(g=> g.role_id == f.id))
			setRoles(rolesFiltered);
			setObjects(users.data);
			setLoaded(true);
		})
	}, [navigate, modals.delete.result, modals.edit.result, modals.create.result]);

	/**
	 * Returns the color associated with a given role.
	 * @param {string} role - The role for which to retrieve the color.
	 * @returns {string} The color associated with the role.
	 */
	const getRoleColor = (role) => {
		return roleColours[role.toLowerCase()];
	}

	const actions = [
		{
			icon: <AddIcon />,
			name: 'Add New User',
			callback: modals.create.open
		}
	];

	const tableBodyMapper = (f) => (
		<TableRow key={`${f.id}_row`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
			<TableCell key={`${f.id}_name`}>{f.name}</TableCell>
			<TableCell key={`${f.id}_surname`}>{f.surname}</TableCell>
			<TableCell key={`${f.id}_email`}>{f.email}</TableCell>
			<TableCell key={`${f.id}_client`}>{f.client?.name}</TableCell>

			<TableCell key={`${f.id}_role`}>
				<Chip key={`${f.id}_highlight`}
					label={f.roles.name}
					sx={{
						backgroundColor: getRoleColor(f.roles.name) && getRoleColor(f.roles.name)['background'],
						color: getRoleColor(f.roles.name) && getRoleColor(f.roles.name)['color'],
						textTransform: 'capitalize'
					}} />
			</TableCell>

			<TableCell key={`${f.id}_actions`}>
				<Typography key={`${f.id}_typo`}>
				{ permissions.Users.update && <IconButton key={`${f.id}_edit`}
						onClick={(e) => modals.edit.open(e, f)}>
						<EditTwoToneIcon />
					</IconButton>
				}
				{ permissions.Users.delete && <IconButton key={`${f.id}_delete`}
						onClick={(e) => modals.delete.open(e, f)}>
						<DeleteTwoToneIcon />
					</IconButton>
				}
				</Typography>
			</TableCell>
		</TableRow>
	);

	const getModalComponent = (modalData) => {
		const validation = modalData && modalData.validation && modalData.validation();
		return (validation && <ModalComponent key={modalData.type} data={modalData}>
			<Box>
				<form noValidate autoComplete='off'>
					{
						<DialogComponent key={modalData.type} data={{...modalData, defaultValue: modalData.selected}}></DialogComponent>
					}
				</form>
			</Box>
		</ModalComponent>
	)};

	const filterOptions = (user, tab, searchValue) => {
		return (tab === 'All' || user.roles.name === tab) &&
		(
			user.name.toLowerCase().includes(searchValue.toLowerCase())
		);
	}

	return loaded && (
		<React.Fragment>
			<TableContent data={{
				groupings: roles,
				objects, 
				body: tableBodyMapper,
				filterOptions,
				screen
			}}>
				<Footer actions={ permissions.Users.create && actions} />
				{
					[
						getModalComponent(modals.create.modalData), 
						getModalComponent(modals.edit.modalData),
						getModalComponent(modals.delete.modalData)
					]
				}
			</TableContent>
		</React.Fragment>
	);
}

export default Users;