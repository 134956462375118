
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import * as axios from 'axios';

import {
	Avatar,
	Button,
	TextField,
	Paper,
	Box,
	Grid,
	Typography,
	createTheme,
	ThemeProvider,
	Container,
	Stepper,
	StepLabel,
	Step,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	FormControlLabel,
	DialogActions,
	DialogContent,
	Link,
	Checkbox
} from '@mui/material';

import DoneTwoToneIcon from '@mui/icons-material/DoneTwoTone';

import { AUTH, CLIENTS } from '../config/api';
import * as ROUTES from '../config/routes';

import Modal from '../components/modal';
import DialogComponent from '../components/common/global-component/dialog/dialog-component';
import { AppPermissionsContext } from '../components/common/app-context/app-permission-context';

const steps = [
	'Personal Information',
	// 'Company Details',
	'Complete Registration'
];

const SignUp = props => {
	const {
		data
	} = props;

	const { storedUser } = useContext(AppPermissionsContext);

	const navigate = useNavigate();
	const mdTheme = createTheme();

	const [status, setStatus] = useState(0);
	const [activeStep, setActiveStep] = useState(0);
	const [modalOpen, setModalOpen] = useState(false);
	const [isValid, setIsValid] = useState(false);
	const [clients, setClients] = useState([]);
	const [userData, setUserData] = useState(data);

	useEffect(() => {

		fetch(`${CLIENTS}/`)
			.then(response => response.json())
			.then(data => setClients(data));

	}, []);

	const handleNext = () => {
		const isLastStepBeforeConfirmation = steps[activeStep + 1] == 'Complete Registration';
		if (isLastStepBeforeConfirmation) {
			editUser();
		}
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleClick = () => {
		navigate(ROUTES.SIGN_IN);
	};

	const handleTerms = () => {

		setModalOpen(true);

	}

	const editUser = () => {
		if (userData.password === userData.confirmpassword) {
			axios({
				data: {
					...userData,
					tandc: userData.tandc === 'on' ? true : false,
					forgot_password: true
				},
				method: 'PATCH',
				url: `${AUTH}/${userData.id}`,
				headers:{
					'Authorization': `Bearer ${storedUser}`
				}
			})
			.then(response => 
				{
					setStatus(response.status);
					setActiveStep((prevActiveStep) => prevActiveStep + 1);
				});
		}
	}

	const formControls = [
		{
			label: "Name",
			placeholder: "Your name, please"
		},
		{
			label: "Surname",
			placeholder: "Your surname, please"
		},
		{
			label: "Email",
			placeholder: "Your email, please"
		},
		{
			label: "Number",
			placeholder: "Your phone number in here"
		},
		{
			label: "Password",
			placeholder: "",
			type: 'password'
		},
		{
			label: "Confirm Password",
			placeholder: "",
			type: 'password',
			name: "ConfirmPassword"
		}
	]

	const modalData = {
		formControls,
		formData: userData,
		setFormData: setUserData
	}

	const collectFormData = (key, val) => {

		setUserData({
			...userData,
			[key]: val
		});

	}

	return (

		<ThemeProvider theme={mdTheme}>

			<Box
				sx={{
					height: '100vh',
					overflow: 'auto',
					flex: '1 1 0%',
					background: 'rgb(248 248 248)'
				}}>

				<Container
					sx={{
						width: '100%',
						margin: '36px auto',
						boxSizing: 'border-box',
						display: 'block',
						paddingLeft: { sm: '18px', md: '27px' },
						paddingRight: { sm: '18px', md: '27px' },
						maxWidth: { md: '960px' }
					}}>

					<Box sx={{ textAlign: 'center' }}>

						<img
							alt=''
							src='/img/transparent-navy.png'
							style={{
								width: '20%'
							}} />

					</Box>

					<Paper
						sx={{
							backgroundColor: 'rgb(255, 255, 255)',
							color: 'rgb(34, 51, 84)',
							transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
							borderRadius: '10px',
							padding: '36px 0px 0px',
							boxShadow: 'rgb(159 162 191 / 18%) 0px 9px 16px, rgb(159 162 191 / 32%) 0px 2px 2px',
							overflow: 'hidden',
							marginTop: '27px'
						}}>

						<Box
							sx={{
								paddingLeft: '36px',
								paddingRight: '36px',
							}}>

							<Typography
								variant='h2'
								sx={{
									margin: '0px 0px 9px',
									fontWeight: '700',
									fontSize: '30px',
									lineHeight: '1.2',
								}}>
								Create Account
							</Typography>

							<Typography
								component='h2'
								variant='h2'
								sx={{
									margin: '0px 0px 27px',
									fontSize: '16px',
									lineHeight: '1.235',
									color: 'rgba(34, 51, 84, 0.7)',
									fontWeight: 'normal',
								}}>
								Fill in the fields below to sign up for a new account.
							</Typography>

						</Box>

						<form
							autoComplete='off'>

							<Stepper
								alternativeLabel
								activeStep={activeStep}
								sx={{
									display: 'flex',
									alignItems: 'flex-start',
									paddingTop: '20px',
									paddingBottom: '20px',
									background: 'rgba(34, 51, 84, 0.02)',
								}}>

								{steps.map((label, index) => {

									const stepProps = {};
									const labelProps = {};

									return (

										<Step
											key={label}
											{...stepProps}
											xs={{
												paddingLeft: '8px',
												paddingRight: '8px',
												flex: '1 1 0%',
												position: 'relative',
											}}>

											<StepLabel {...labelProps}>
												{label}
											</StepLabel>

										</Step>

									);

								})}

							</Stepper>

							<Box
								sx={{ padding: '36px' }}>

								<Grid
									container
									spacing={4}
									sx={{
										boxSizing: 'border-box',
										display: 'flex',
										flexFlow: 'row wrap',
										marginTop: '-36px',
										width: 'calc(100% + 36px)',
										marginLeft: '-36px',
										'& .MuiInputLabel-root': {
											fontSize: '14px',
											fontWeight: 400,
											lineHeight: '1.4375em',
											color: 'rgb(34, 51, 84)',
											boxSizing: 'border-box',
											cursor: 'text',
											display: 'inline-flex',
											alignItems: 'center',
											width: '100%',
											borderRadius: '10px'
										}
									}}>

									{activeStep === 0 &&

										<>
											{<DialogComponent key={'Edit'} data={{...modalData, defaultValue: userData}}></DialogComponent>}
											<Grid
												item
												xs={12}
												md={6}
												sx={{
													boxSizing: 'border-box',
													margin: '0px',
													flexDirection: 'row',
													flexBasis: { xs: '100%', md: '50%' },
													flexGrow: 0,
													maxWidth: { xs: '100%', md: '50%' }
												}}>

												<FormControlLabel
													label={

														<Typography
															component='span'
															sx={{
																margin: '0px',
																fontSize: '14px',
																fontFamily: 'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
																fontWeight: 400,
																lineHeight: 1.43
															}}>

															<span>I accept the </span>

															<Link
																onClick={handleTerms}>
																Terms and Conditions
															</Link>

														</Typography>
													}
													control={

														<Checkbox
														onChange={(e) => collectFormData('tandc', e.target.value)} />

													}
													sx={{
														display: 'inline-flex',
														alignItems: 'center',
														cursor: 'pointer',
														verticalAlign: 'middle',
														tapHighlightColor: 'transparent',
														marginLeft: '-11px',
														marginRight: '16px'
													}}>

												</FormControlLabel>

											</Grid>
										</>

									}

									{activeStep === 1 && status === 200 &&

										<Box
											sx={{
												padding: '72px 36px',
												width: '100%'
											}}>

											<Container
												maxWidth='sm'
												sx={{
													width: '100%',
													marginLeft: 'auto',
													boxSizing: 'border-box',
													marginRight: 'auto',
													display: 'block',
													paddingLeft: { xs: '18px', md: '27px' },
													paddingRight: { xs: '18px', md: '27px' },
													maxWidth: '600px'
												}}>

												<Avatar
													sx={{
														position: 'relative',
														display: 'flex',
														alignItems: 'center',
														justifyContent: 'center',
														flexShrink: '0',
														lineHeight: '1',
														borderRadius: '50%',
														overflow: 'hidden',
														userSelect: 'none',
														fontSize: '14px',
														fontWeight: 'bold',
														background: 'rgb(87, 202, 34)',
														color: 'rgb(255, 255, 255)',
														width: '108px',
														height: '108px',
														boxShadow: 'rgb(68 214 0 / 25%) 0px 1px 4px, rgb(68 214 0 / 35%) 0px 3px 12px 2px',
														marginLeft: 'auto',
														marginRight: 'auto',
														'& .MuiSvgIcon-root': {
															fontSize: '2.8125rem'
														}
													}}>

													<DoneTwoToneIcon />

												</Avatar>

												<Typography
													align='center'
													component='h2'
													sx={{
														margin: '0px',
														fontWeight: 700,
														fontSize: '35px',
														textAlign: 'center',
														padding: '45px 90px 36px',
														lineHeight: '1.5'
													}}>

													Your account is all setup and ready to go!

												</Typography>

												<Button
													variant='contained'
													fullWidth={true}
													size='medium'
													onClick={handleClick}
													sx={{
														color: 'rgb(255, 255, 255)',
														backgroundColor: 'rgb(36, 66, 175)',
														boxShadow: 'none',
														width: '100%',
														fontWeight: 'bold',
														textTransform: 'none',
														padding: '8px 20px',
													}}>
													Continue to Sign In
												</Button>

											</Container>

										</Box>

									}

								</Grid>

							</Box>

							{activeStep <= steps.length - 2 &&

								<Box sx={{
									padding: '36px',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'space-between',
									background: 'rgba(34, 51, 84, 0.02)',
									'& .MuiButtonBase-root.Mui-disabled': {
										color: 'rgba(34, 51, 84, 0.5)',
										border: '1px solid rgba(34, 51, 84, 0.02)',
										background: 'transparent'
									},
									'& .MuiButtonBase-root': {
										display: 'inline-flex',
										alignItems: 'center',
										justifyContent: 'center',
										position: 'relative',
										boxSizing: 'border-box',
										outline: '0px',
										border: '0px',
										margin: '0px',
										cursor: 'pointer',
										userSelect: 'none',
										verticalAlign: 'middle',
										appearance: 'none',
										textDecoration: 'none',
										fontSize: '0.875rem',
										lineHeight: '1.75',
										minWidth: '64px',
										borderRadius: '10px',
										transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
										color: 'rgb(255, 255, 255)',
										backgroundColor: 'rgb(36, 66, 175)',
										boxShadow: 'none',
										fontWeight: 'bold',
										textTransform: 'none',
										padding: '8px 20px',
										'&:hover': {
											color: 'rgb(255, 255, 255)',
											backgroundColor: 'rgb(36, 66, 175)',
										}
									}
								}}>

									<Button
										color="inherit"
										disabled={activeStep === 0}
										onClick={handleBack}
										sx={{ mr: 1 }}>
										Previous
									</Button>

									<Button onClick={handleNext}>
										{activeStep === steps.length - 2 ? 'Complete Registration' : 'Next step'}
									</Button>

								</Box>

							}

						</form>

					</Paper>

				</Container>

			</Box>

			<Modal
				toggle={() => setModalOpen(false)}
				state={modalOpen}>

				<Typography
					component='h2'
					sx={{
						margin: '0px',
						fontSize: '15px',
						fontWeight: '500',
						lineHeight: '1.6',
						flex: '0 0 auto',
						padding: '27px'
					}}>

					<Typography
						variant='h4'
						component='div'
						sx={{
							margin: '0px 0px 4px',
							fontWeight: '700',
							fontSize: '16px',
							lineHeight: '1.235',
						}}>

						Terms and Conditions

					</Typography>

					<Typography
						variant='subtitle2'
						component='div'
						sx={{
							margin: '0px',
							fontWeight: '400',
							fontSize: '15px',
							color: 'rgba(34, 51, 84, 0.7)',
							lineHeight: '1.57'
						}}>

						Please read and accept our terms and conditions to continue using the application

					</Typography>

				</Typography>

				<DialogContent
					sx={{
						flex: '1 1 auto',
						overflowY: 'auto',
						borderTop: '1px solid rgba(0, 0, 0, 0.12)',
						borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
						padding: '27px',
						width: '100%',
						color: 'rgba(34, 51, 84, 0.7)',
						fontFamily: 'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
						lineHeight: '1.57',
						fontSize: '15px'
					}}>

					<p>CONTENT COMING SOON!</p>

				</DialogContent>

				<DialogActions
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'flex-end',
						flex: '0 0 auto',
						padding: '27px',
					}}>

					<Button
						onClick={() => setModalOpen(false)}
						sx={{
							color: 'rgb(255, 255, 255)',
							backgroundColor: 'rgb(36, 66, 175)',
							boxShadow: 'none',
							fontWeight: 'bold',
							textTransform: 'none',
							padding: '8px 20px',
							'&:hover': {
								color: 'rgb(255, 255, 255)',
								backgroundColor: 'rgb(36, 66, 175)',
							}
						}}>
						Close
					</Button>

				</DialogActions>

			</Modal>

		</ThemeProvider>

	);

}

export default SignUp;
