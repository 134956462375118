
import React from 'react';
import {
	IconButton,
	FormControl,
	OutlinedInput,
	InputAdornment
} from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';

const searchFormControl = {
    display: 'inline-flex',
    flexDirection: 'column',
    position: 'relative',
    minWidth: '0px',
    padding: '0px',
    border: '0px',
    verticalAlign: 'top',
    width: '100%',
    margin: '0px',
    padding: '18px',
    '& .css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
        display: 'none'
    },
    '& .css-1a36qti-MuiInputBase-root-MuiOutlinedInput-root': {
        border: '1px solid rgba(0, 0, 0, 0.23)',
        borderRadius: '4px',
    }
};

const searchOutlineInput = {
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: '400',
    fontSize: '1rem',
    lineHeight: '1.4375em',
    letterSpacing: '0.00938em',
    color: 'rgba(0, 0, 0, 0.87)',
    boxSizing: 'border-box',
    position: 'relative',
    cursor: 'text',
    display: 'inline-flex',
    alignItems: 'center',
    bordeRradius: '4px',
};

const SearchComponent = (props) => {
    const {
        data:{
            fullWidth,
            placeholder,
            size,
            type
        },
        setSearchTerm
    } = props;

    return (
		<React.Fragment>
			<FormControl sx={ searchFormControl }>
                <OutlinedInput
                    type={type}
                    placeholder={placeholder}
                    fullWidth={fullWidth}
                    size={size}
                    endAdornment={
                        <InputAdornment position='start'>
                            <IconButton>
                                <SearchIcon />
                            </IconButton>
                        </InputAdornment>
                    }
                    sx={ searchOutlineInput }
                    onChange={(e) => setSearchTerm(e.target.value)}>
                </OutlinedInput>
            </FormControl>
		</React.Fragment>
    )
}

export default SearchComponent;