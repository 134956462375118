
import React from 'react';
import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';

const QuickMenu = (props) => {

	const { actions } = props;

	return (

		<SpeedDial
			ariaLabel='quick menu'
			icon={<SpeedDialIcon />}
			sx={{
				position: 'fixed',
				bottom: 16,
				right: 16
			}}>

			{actions.map((action) => (

				<SpeedDialAction
					key={action.name}
					icon={action.icon}
					onClick={action.callback}
					tooltipTitle={action.name} />

			))}

		</SpeedDial>

	)

}

export default QuickMenu;
