
import React, {
	useState,
	useEffect,
	useContext
} from 'react';

import {
	useNavigate,
} from 'react-router-dom';

import * as axios from 'axios';

import {
	Button,
	CssBaseline,
	TextField,
	Paper,
	Box,
	Container,
	Typography,
	createTheme,
	ThemeProvider,
	FormControl,
	Card,
	FormControlLabel,
	Checkbox,
	Link,
	DialogContent,
	DialogActions,
} from '@mui/material';

import { AUTH, ROLEPERMISSION } from '../config/api';
import Modal from '../components/modal';
import * as ROUTES from '../config/routes';
import { AppPermissionsContext } from '../components/common/app-context/app-permission-context';
import { jwtDecode } from "jwt-decode";
import logo from '../pages/images/background-image.png';
// import { backgroundImage } from "../pages/images"

const SignIn = () => {

	const navigate = useNavigate();
	const mdTheme = createTheme();

	const { setStoredUser, setDecoded, setPermission } = useContext(AppPermissionsContext);
	

	const [modalOpen, setModalOpen] = useState(false);
	const [firstTime, setFirstTime] = useState(null);

	const [adminCreds, setAdminCreds] = useState({
		email: '',
		password: ''
	});

	useEffect(() => {
		setFirstTime(JSON.parse(window.localStorage.getItem('first-time')));
		window.localStorage.removeItem('user');
	}, []);

	/**
	 * Updates the adminCreds state with the provided key-value pair.
	 * Sets the specified key in the adminCreds state with the provided value.
	 * @param {string} key - The key to update in the adminCreds state.
	 * @param {string} val - The value to assign to the specified key.
	 */
	const updateAdminCreds = (key, val) => {
		setAdminCreds({
			...adminCreds,
			[key]: val
		});
	}

	/**
	 * Handles the form submission.
	 * Makes a POST request to the server with the adminCreds.
	 * If the response contains an 'id' property, stores the user data in local storage and navigates to the admin dashboard.
	 */
	const handleSubmit = () => {
		axios({
			data: adminCreds,
			method: 'POST',
			url: `${AUTH}/sign-in`,
		})
		.then(response => {
			if (response.data.access_token) {
				window.localStorage.setItem('user', response.data.access_token);
				if (firstTime === null) {
					window.localStorage.setItem('first-time', 'true');
				}
				setStoredUser(response.data.access_token);
				const decode = jwtDecode(response.data.access_token);
				setDecoded(decode);

				axios({
					method: 'GET',
					url: `${ROLEPERMISSION}`,
					headers:{
					  'Authorization': `Bearer ${response.data.access_token}`
					}
				  }).then(response => {
					setPermission(response.data);
				  })
				
				setTimeout(f=> {
					navigate(ROUTES.ADMIN_DASHBOARD);		
				}, 1000)
				
			}
		})
		.catch(err => {console.log(err);});
	}

	/**
	 * Handles the terms button click.
	 * Opens the modal by setting the modalOpen state to true.
	 */
	const handleTerms = () => {
		setModalOpen(true);
	}


	return (

		<ThemeProvider theme={mdTheme}>

			<Box sx={{
				display: 'flex',
				flex: '1 1 0%',
				width: '100%',
			}}>

				<Box sx={{
					display: { xs: 'none', md: 'flex' },
					left: '0px',
					top: '0px',
					height: '100%',
					background: 'rgb(255, 255, 255)',
					width: '440px',
					position: 'fixed'
				}}>

					<div style={{
						position: 'relative',
						overflow: 'hidden',
						width: '100%',
						height: '100vh'
					}}>

						<div style={{
							position: 'absolute',
							inset: '0px',
							marginRight: '0px',
							marginBottom: '0px',
						}}>

							<Box sx={{
								display: 'flex',
								flexDirection: 'column',
								padding: '54px',
							}}>

								<img src='/img/transparent-navy.png'
									alt='alt'
									style={{
										color: 'rgb(34, 51, 84)',
										padding: '0px 9px 0px 0px',
										display: 'flex',
										textDecoration: 'none',
										fontWeight: '700',
										width: '80%'
									}} />

								<Box
									sx={{
										marginTop: '54px'
									}}>

									<Typography
										variant='h1'
										sx={{
											margin: '0px 0px 30px',
											fontWeight: '700',
											lineHeight: '1.167',
											fontSize: '2.0625rem',
										}}>
										Transfer Pricing Advisory
									</Typography>

									<Typography
										variant='subtitle1'
										sx={{
											margin: '27px 0px',
											fontSize: '14px',
											color: 'rgba(34, 51, 84, 0.7)',
											fontWeight: '400',
											lineHeight: '1.75',
										}}>
										The Graphene Economics Technology platform is an online solution which uses rich transfer pricing, Country-by-Country and other tax related data sets to provide critical business insights. Our core offering is descriptive analytics which allows for improving and deepening the understanding of certain information that is routinely gathered but usually buried into infinite amounts of quantitative data and sorted into large Excel files.
									</Typography>

									<Typography
										component='div'
										sx={{
											margin: '0px',
											fontSize: '14px',
											lineHeight: '1.75',
											color: 'rgb(34, 51, 84)',
											fontWeight: '700',
										}}>
										Our services range from Country-by-Country Report upload solutions, to industry and market comparative analyses.
									</Typography>

									<Typography
										component='div'
										sx={{
											margin: '5px 0px',
											fontSize: '14px',
											color: 'rgba(34, 51, 84, 0.7)',
											fontWeight: '400',
											lineHeight: '1.75',
										}}>
										These analytics are critical to the transfer pricing life cycle, from strategy and planning to price setting, compliance, intercompany financial models and even dispute resolution.
									</Typography>

								</Box>

							</Box>

						</div>

					</div>

				</Box>

				<Box
					sx={{
						padding: '0px 0px 0px 440px',
						width: '100%',
						display: 'flex',
						alignItems: 'center',
						height: '100vh',
						backgroundImage: `url(${logo})`,
						backgroundSize: '100vw 100vh'
					}}>
						<CssBaseline />

						<Container
							sx={{
								width: '100%',
								marginLeft: 'auto',
								boxSizing: 'border-box',
								marginRight: 'auto',
								display: 'flex',
								alignItems: 'center',
								flexDirection: 'column',
							}}
							maxWidth="sm">

							<Paper
								component={Card}
								elevation={1}
								sx={{
									backgroundColor: 'rgb(255, 255, 255)',
									
									color: 'rgb(34, 51, 84)',
									transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
									borderRadius: '10px',
									boxShadow: 'rgb(159 162 191 / 18%) 0px 9px 16px, rgb(159 162 191 / 32%) 0px 2px 2px',
									overflow: 'hidden',
									padding: '36px',
									marginTop: '36px',
									marginBottom: '36px',
									width: '100%',
								}}>

								<Box sx={{ textAlign: 'center' }}>

									<Typography
										component='h2'
										variant='h2'
										sx={{
											margin: '0px 0px 9px',
											fontWeight: '700',
											fontSize: '30px',
											lineHeight: '1.2',
										}}>
										Sign In
									</Typography>

									<Typography
										component='div'
										sx={{
											margin: '0px 0px 27px',
											fontSize: '16px',
											lineHeight: '1.235',
											color: 'rgba(34, 51, 84, 0.7)',
											fontWeight: 'normal'
										}}>
										Fill in the fields below to sign into your account.
									</Typography>

									<form noValidate>

										<FormControl
											sx={{
												display: 'inline-flex',
												flexDirection: 'column',
												position: 'relative',
												minWidth: '0px',
												padding: '0px',
												margin: '16px 0px 8px',
												border: '0px',
												verticalAlign: 'top',
												width: '100%',
											}}>

											<TextField
												type='email'
												label="Email Address"
												fullWidth
												onChange={(e) => updateAdminCreds('email', e.target.value)}
												placeholder='Your valid email address'
												variant="outlined" 
												/>

										</FormControl>

										<FormControl
											sx={{
												display: 'inline-flex',
												flexDirection: 'column',
												position: 'relative',
												minWidth: '0px',
												padding: '0px',
												margin: '16px 0px 8px',
												border: '0px',
												verticalAlign: 'top',
												width: '100%',
											}}>

											<TextField
												type='password'
												label="Password"
												onChange={(e) => updateAdminCreds('password', e.target.value)}
												fullWidth
												placeholder='That password only you know of'
												variant="outlined" />

										</FormControl>

										<Box
											sx={{
												alignItems: 'center',
												display: 'flex',
												justifyContent: 'space-between'
											}}>

											<Typography
												component='a'
												href='/forgot-password'
												sx={{
													margin: '20px 0 0',
													color: 'rgb(36, 66, 175)',
													textDecoration: 'none',
													fontWeight: 700,
													fontSize: '0.9em'
												}}>
												<strong>Forgot Password?</strong>
											</Typography>

										</Box>


									</form>

									<Button
										size='large'
										fullWidth={true}
										variant='contained'
										onClick={handleSubmit}
										sx={{
											display: 'inline-flex',
											alignItems: 'center',
											justifyContent: 'center',
											position: 'relative',
											boxSizing: 'border-box',
											outline: '0px',
											border: '0px',
											margin: '27px 0px 0px',
											cursor: 'pointer',
											userSelect: 'none',
											verticalAlign: 'middle',
											appearance: 'none',
											textDecoration: 'none',
											fontSize: '0.9375rem',
											lineHeight: '1.75',
											minWidth: '64px',
											borderRadius: '10px',
											transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
											color: 'rgb(255, 255, 255)',
											backgroundColor: 'rgb(36, 66, 175)',
											boxShadow: 'none',
											width: '100%',
											fontWeight: 'bold',
											textTransform: 'none',
											padding: '11px 24px',
										}}>
										Sign In
									</Button>
								</Box>

							</Paper>

						</Container>

				</Box>

			</Box>

			<Modal
				toggle={() => setModalOpen(false)}
				state={modalOpen}>

				<Typography
					component='h2'
					sx={{
						margin: '0px',
						fontSize: '15px',
						fontWeight: '500',
						lineHeight: '1.6',
						flex: '0 0 auto',
						padding: '27px'
					}}>

					<Typography
						variant='h4'
						component='div'
						sx={{
							margin: '0px 0px 4px',
							fontWeight: '700',
							fontSize: '16px',
							lineHeight: '1.235',
						}}>

						Terms and Conditions

					</Typography>

					<Typography
						variant='subtitle2'
						component='div'
						sx={{
							margin: '0px',
							fontWeight: '400',
							fontSize: '15px',
							color: 'rgba(34, 51, 84, 0.7)',
							lineHeight: '1.57'
						}}>

						Please read and accept our terms and conditions to continue using the application

					</Typography>

				</Typography>

				<DialogContent
					sx={{
						flex: '1 1 auto',
						overflowY: 'auto',
						borderTop: '1px solid rgba(0, 0, 0, 0.12)',
						borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
						padding: '27px',
						width: '100%',
						color: 'rgba(34, 51, 84, 0.7)',
						fontFamily: 'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
						lineHeight: '1.57',
						fontSize: '15px'
					}}>

					<p>CONTENT COMING SOON!</p>

				</DialogContent>

				<DialogActions
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'flex-end',
						flex: '0 0 auto',
						padding: '27px',
					}}>

					<Button
						onClick={() => setModalOpen(false)}
						sx={{
							color: 'rgb(255, 255, 255)',
							backgroundColor: 'rgb(36, 66, 175)',
							boxShadow: 'none',
							fontWeight: 'bold',
							textTransform: 'none',
							padding: '8px 20px',
							'&:hover': {
								color: 'rgb(255, 255, 255)',
								backgroundColor: 'rgb(36, 66, 175)',
							}
						}}>
						Close
					</Button>

				</DialogActions>

			</Modal>

		</ThemeProvider>

	);

}

export default SignIn;
