
import React, {useState, useEffect} from 'react';
import {
	useNavigate
} from 'react-router-dom';

import {
	Box,
	Button,
	List,
	ListItem,
	ListSubheader,
	ListItemText
} from '@mui/material';

import ListItemIcon from '@mui/material/ListItemIcon';
import TodayIcon from '@mui/icons-material/Today';
import DateRangeIcon from '@mui/icons-material/DateRange';
import SettingsIcon from '@mui/icons-material/Settings';
import MenuList from './common/menu-list/menu-list';

import * as ROUTES from '../config/routes';

export const PrimaryNavigation = () => {
	return (

		<>

			<List sx={{
				listStyle: 'none',
				margin: '0px',
				position: 'relative',
				padding: '12px'
			}}>
				<ListSubheader
					sx={{
						background: 'transparent',
						boxSizing: 'border-box',
						listStyle: 'none',
						fontFamily: 'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
						textTransform: 'uppercase',
						fontSize: '0.75rem',
						fontWeight: 'bold',
						color: 'rgba(255, 255, 255, 0.5)',
						padding: '0px 22.5px',
						lineHeight: '1.4'
					}}>
					Navigation
				</ListSubheader>
				<Box>
					<MenuList/>
				</Box>
			</List>
		</>
	)

};

export const secondaryNavigation = (

	<>

		<ListSubheader inset>
			Annual CbC Reports
		</ListSubheader>

		<ListItem button>

			<ListItemIcon>
				<TodayIcon />
			</ListItemIcon>

			<ListItemText primary="Current Year" />

		</ListItem>

		<ListItem button>

			<ListItemIcon>
				<DateRangeIcon />
			</ListItemIcon>

			<ListItemText primary="Previous Years" />

		</ListItem>

	</>

);

export const settingsNavigation = (

	<>

		<ListItem button>

			<ListItemIcon>
				<SettingsIcon />
			</ListItemIcon>

			<ListItemText primary="Settings" />

		</ListItem>

	</>

);
