import React, {
	useState,
	useEffect,
	useContext
} from 'react';

import {
	Routes,
	useNavigate
} from 'react-router-dom';

import {
	Box,
	IconButton,
	Typography,
	TableCell,
	TableRow,
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { ANALYTICS_CONSOLE } from '../config/api';
import Footer from '../components/footer';
import ModalComponent from '../components/common/global-component/modal/modal-component';
import DialogComponent from '../components/common/global-component/dialog/dialog-component';
import ModalService from '../services/modal-service';
import { AppPermissionsContext } from '../components/common/app-context/app-permission-context';
import { getAnalyticsConsole, getDataSources, getReportList, getClients } from '../services/api-services';
import TableContent from '../components/common/pages/table-content-components';
import * as ROUTES from '../config/routes';

const screen = {
	type: 'Analytic Console',
	heading: {
		title: 'Analytics Console',
		description: 'Create a console based on data sources and get a comprehensive view over your CbC Reporting'
	},
	search: {
		type:'text',
		placeholder:'Search by client.',
		fullWidth:true,
		size:'small'
	},
	tableHead: [
		'Name',
		'Company',
		'Period',
		'Template',
		'Datasources',
		''
	]
}

const AnalyticConsole = () => {
    const [objects, setObjects] = useState([]);
	const { storedUser, permissions } = useContext(AppPermissionsContext);
	const [reportList, setReportList] = useState([]);
	const [datasources, setDatasources] = useState([]);
	const [clients, setClients] = useState([]);

	const navigate = useNavigate();

	const formControls = [
		{
			label: "Name",
			placeholder: "Your client name, please",
			helperText: "Name is required",
			required: true,
			validation: (obj) => true
		},
		{
			label: "Company",
			placeholder: "Select your company, please",
			name: "companyId",
			type: 'selectbox',
			options: clients,
		},
		{
			label: "Period",
			placeholder: "Select your period, please",
			name: "year",
			helperText: "Period is required",
			required: true,
			validation: (obj) => true
		},
		{
			label: "Template",
			placeholder: "Select your template, please",
			type: 'selectbox',
			options: reportList,
			name: "template",
			defaultValue: "1956f88a-3274-4c96-8997-88cd5b47cfb7"
		},
		{
			label: "Datasources",
			placeholder: "Select your datasource, please",
			type: 'selectbox',
			options: datasources,
			name: "file"
		}
	]

	const createModal = {
		form: {
			formControls,
			processText: 'Add new console',
			title: 'Create New Console',
			validation: () => true,
			type: 'create'
		},
		request: {
			method: 'POST',
			requestValidation: () => true,
			url: () => `${ANALYTICS_CONSOLE}/create`,
		}
	}

	const editModal = {
		form: {
			description: 'Configure your console settings',
			formControls,
			processText: 'Edit existing client',
			title: 'Edit Existing Client',
			validation: () => true,
			type: 'edit'
		},
		request: {
			method: 'PATCH',
			requestValidation: () => modals.edit.modalData.selected,
			url: () => `${ANALYTICS_CONSOLE}/${modals.edit.modalData.selected.id}`,
		}
	}

	const deleteModal = {
		form: {
			description: 'Are you sure, you know what you doing?',
			formControls: [
				{
					label: "Name",
					type: 'readonly',
					disabled: true,
				},
				{
					label: "ID",
					type: 'readonly',
					disabled: true,
				}
			],
			processText: 'Delete existing client',
			title: 'Delete Existing Client',
			validation: () => true,
			type: 'delete'
		},
		request: {
			method: 'DELETE',
			requestValidation: () => modals.delete.modalData.selected.id,
			url: () => `${ANALYTICS_CONSOLE}/${modals.delete.modalData.selected.id}`,
		}
	}

	const modals = {
		create: ModalService(createModal),
		edit: ModalService(editModal),
		delete: ModalService(deleteModal),
	}

	useEffect(() => {
		setReportList([{
			id: '1956f88a-3274-4c96-8997-88cd5b47cfb7',
			name: 'Analytics Template'
		}]);	
		getDataSources(storedUser)
			.then(response => {
				setDatasources(response.data);	
			});

		getClients(storedUser)
			.then(response =>{
				setClients(response.data);
			});
	}, [navigate, modals.delete.result, modals.edit.result, modals.create.result]);

	useEffect(() => {
		getAnalyticsConsole(storedUser)
			.then(response =>{
				response.data = response.data.map(f=> ({...f, templateName: getCurrentTemplate(f.template)}))
				setObjects(response.data);
			});
	}, [reportList]);

	const actions = [
		{
			icon: <AddIcon />,
			name: 'Add New Console',
			callback: modals.create.open
		}
	];

	const filterOptions = (obj, _, searchValue) => {
		return obj.name.toLowerCase().includes(searchValue.toLowerCase()) ||
		obj.client.name.toLowerCase().includes(searchValue.toLowerCase()) ||
		obj.templateName.toLowerCase().includes(searchValue.toLowerCase()) ||
		obj.year.toLowerCase().includes(searchValue.toLowerCase())
	}

	const getCurrentTemplate = (id) =>reportList.find(f=> f.id == id)?.name ?? 'N\A';

	const tableBodyMapper = (f) => (
		<TableRow key={`${f.id}_row`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
			{/* <TableCell key={`${f.id}_id`}>{f.id}</TableCell> */}
			<TableCell key={`${f.id}_name`}>{f.name}</TableCell>
			<TableCell key={`${f.id}_company`}>{f.client.name}</TableCell>
			<TableCell key={`${f.id}_period`}>{f.year}</TableCell>
			<TableCell key={`${f.id}_template`}>{f.templateName}</TableCell>
			<TableCell key={`${f.id}_datasources`}>{f.datasource.file}</TableCell>

			<TableCell key={`${f.id}_actions`}>
				<Typography key={`${f.id}_typo`}>
					{ permissions && permissions['Analytics Console'] && permissions['Analytics Console'].read && <IconButton key={`${f.id}_show`}
						onClick={(e) => navigate(`${ROUTES.ANALYTIC_CONSOLES_SHOW}/${f.id}`)}>
						<VisibilityIcon />
					</IconButton>
					}
					{ permissions && permissions['Analytics Console'] && permissions['Analytics Console'].update && false && <IconButton key={`${f.id}_edit`}
						onClick={(e) => modals.edit.open(e, f)}>
						<EditTwoToneIcon />
					</IconButton>
					}
					{ permissions && permissions['Analytics Console'] && permissions['Analytics Console'].delete && <IconButton key={`${f.id}_delete`}
						onClick={(e) => modals.delete.open(e, f)}>
						<DeleteTwoToneIcon />
					</IconButton>
					}
				</Typography>
			</TableCell>
		</TableRow>
	);

	const getModalComponent = (modalData) => {
		const validation = modalData && modalData.validation && modalData.validation();
		return (validation && <ModalComponent key={modalData.type} data={modalData}>
			<Box>
				<form noValidate autoComplete='off'>
					{
						<DialogComponent key={modalData.type} data={{...modalData, defaultValue: modalData.selected}}></DialogComponent>
					}
				</form>
			</Box>
		</ModalComponent>
	)};

	return (
		<React.Fragment>
			<TableContent data={{
				objects, 
				body: tableBodyMapper,
				filterOptions,
				screen
			}}>
				<Footer actions={ permissions.Users.create && actions} />
				{
					[
						getModalComponent(modals.create.modalData), 
						getModalComponent(modals.edit.modalData),
						getModalComponent(modals.delete.modalData)
					]
				}
			</TableContent>
		</React.Fragment>
	);
}

export default AnalyticConsole;