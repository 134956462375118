import React, {
	useState,
	useEffect,
	useContext
} from 'react';

import {
	useNavigate
} from 'react-router-dom';

import {
	Box,
	IconButton,
	Typography,
	TableCell,
	TableRow,
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';

import { CLIENTS } from '../config/api';
import Footer from '../components/footer';
import ModalComponent from '../components/common/global-component/modal/modal-component';
import DialogComponent from '../components/common/global-component/dialog/dialog-component';
import ModalService from '../services/modal-service';
import { AppPermissionsContext } from '../components/common/app-context/app-permission-context';
import { getClients} from '../services/api-services';
import TableContent from '../components/common/pages/table-content-components';

import TableComponent from '../components/common-new/table-component';

const screen = {
	type: 'Clients',
	heading: {
		title: 'Client Management',
		description: 'All aspects related to clients can be managed from this page.'
	},
	search: {
		type:'text',
		placeholder:'Search by client.',
		fullWidth:true,
		size:'small'
	},
	tableHead: [
		'Name',
		'Description',
		''
	]
}

const Clients = () => {
    const [objects, setObjects] = useState([]);
	const { storedUser, permissions } = useContext(AppPermissionsContext);
	const navigate = useNavigate();

	const { Clients } = permissions;

	const loadData = async () => {
		getClients(storedUser)
			.then(response =>{
				setObjects(response.data);
			});
	}

	const deleteModal = {
		form: {
			description: 'Are you sure, you know what you doing?',
			formControls: screen.tableHead.filter(f=> f != "").map(f=> 
			{ 
				return {
					label: f,
					type: 'readonly',
					disabled: true,
				}
			}),
			processText: 'Delete existing client',
			title: 'Delete Existing Client',
			validation: () => true,
			type: 'delete'
		},
		request: {
			method: 'DELETE',
			requestValidation: () => modals.delete.modalData.selected.id,
			url: () => `${CLIENTS}/${modals.delete.modalData.selected.id}`,
		}
	}

	const modals = {
		delete: ModalService(deleteModal),
	}

	const { edit, remove } = { 
		edit: { 
			data: Clients.update, 
			click: () => {} 
		}, 
		remove: { 
			data: Clients.delete, 
			click: modals.delete.open 
		} 
	};

	const actions = [
		{
			icon: <AddIcon />,
			name: 'Add New Client',
			callback: () => {}
		}
	];

	useEffect(() => {
		loadData();
	}, [navigate, modals.delete.result]);

	return screen && objects && (
		<React.Fragment>
			<TableComponent data={ { edit, loadData, objects, remove, screen } }></TableComponent>
			<Footer actions={ permissions.Users.delete && actions } />
			{
				[
					modals.delete.getModalComponent(), 
				]
			}
		</React.Fragment>
	);
}

export default Clients;