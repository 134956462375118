

import React, {
	useState,
	useEffect,
	useContext
} from 'react';

import {
	Box,
	Typography,
	Grid,
	Button,
	Paper,
	Divider,
	DialogContent,
	DialogActions
} from '@mui/material';
import { headerSX, descriptionSX} from '../components/common/header-component/style-constant';
import { AppPermissionsContext } from '../components/common/app-context/app-permission-context';
import cbcrMap from '../pages/images/CbCR.png';
import cbcRInfoPDF from '../pages/documents/CbCR_infographic.pdf';
import cbcrGuidance from '../pages/documents/Guidance_of_CbCR.pdf';
import newcbcrMap from '../pages/images/NewCbCR.png';

const Dashboard = () => {
	const { decoded } = useContext(AppPermissionsContext);

	const cardConfigSx = {
		transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
		borderRadius: '10px',
		boxShadow: 'rgb(159 162 191 / 18%) 0px 9px 16px, rgb(159 162 191 / 32%) 0px 2px 2px',
		alignItems: 'center',
		padding: '3rem',
		width: '70%'
	}

	const contentConfigSx = {
		margin: '0px',
		fontWeight: 300,
		lineHeight: '1.167',
		fontSize: '1.75rem'
	}

	const hyperLinkCard = (content) => (<React.Fragment>
		<Typography
			sx={content.contentsx}
			variant='h1'>
			{content.title}
		</Typography>

		<br/>

		<Typography
			sx={content.contentsx}
			variant='h1'>
			<l>
				<li>
					<a target='blank' href={cbcRInfoPDF}>CbCR Filing Guide</a>
				</li>
				<br></br>
				<li>
					<a target='blank' href={cbcrGuidance}>Definitions</a>
				</li>
				<br></br>
				<li>
					<a target='blank' href='https://www.oecd.org/tax/automatic-exchange/country-by-country-exchange-relationships.htm'>Exchange of information</a>
				</li>
				<br></br>
				<li>
					<a target='blank' href='https://www.oecd.org/tax/automatic-exchange/about-automatic-exchange/CbC-MCAA-Signatories.pdf'>Signatories of the MCAA on the exchange of CbCR</a>
				</li>
			</l>
		</Typography>
	</React.Fragment>);

	const contentGenerator = (content) => content.map(f=> {
		return (
		<React.Fragment>
			<Box 
				variantion='rounded'
				sx={f.sx}>
				<Typography
					sx={f.contentsx}
					variant='h1'>
					{(f.type == 'hyperlink' && hyperLinkCard(f) || f.type == undefined && f.title)}
				</Typography>
			</Box>
		</React.Fragment>
	)});

	const firstColumn = [
		{ title: 'Anonymised analytics and insights', sx: { ...cardConfigSx, backgroundColor: 'rgb(0, 26, 72)', color: 'rgb(255, 255, 255)' }, contentsx: { ...contentConfigSx, height: '6rem',	alignItems: 'center', display: 'flex'}},
		{ title: <a target='blank' href='https://www.oecd-ilibrary.org/docserver/f1f07219-en.pdf?expires=1707144696&id=id&accname=guest&checksum=A8FD8BFCECD849A5DA95B228735DDD45'>
			OECD Corporate tax statistics
		</a>,
			 sx: { ...cardConfigSx, backgroundColor: 'rgb(255, 255, 255)', color: 'rgb(0, 26, 72)' }, contentsx: { ...contentConfigSx, height: '3rem',	alignItems: 'center', display: 'flex', fontSize: '1.25rem' }},
		{ title: 'Countries where MNEs submitted CbCR', 
		sx: { ...cardConfigSx, height: '13rem', color: 'rgb(0, 26, 72)',paddingTop:'0rem', backgroundImage: `url(${cbcrMap})`,backgroundSize: '26vw 24vh', backgroundRepeat: 'no-repeat',
				backgroundPosition:'-2vw 2vw'},
		contentsx: { ...contentConfigSx, height: '3rem', alignItems: 'center', display: 'flex', fontSize: '1.25rem' }},
	]
	
	const secondColumn = [
		{ title: 'CbCR Reporting Requirements', sx: { ...cardConfigSx, backgroundColor: 'rgb(0, 26, 72)', color: 'rgb(255, 255, 255)' }, contentsx: { ...contentConfigSx, height: '6rem', alignItems: 'center', display: 'flex'}},
		{ title: <a target='blank' href='https://www.oecd.org/tax/automatic-exchange/country-specific-information-on-country-by-country-reporting-implementation.htm#cbcrequirements'>
			Overview of reporting requirements per jurisdiction</a>, 
			sx: { ...cardConfigSx, backgroundColor: 'rgb(255, 255, 255)', color: 'rgb(0, 26, 72)' }, contentsx: { ...contentConfigSx, height: '3rem', alignItems: 'center', display: 'flex', fontSize: '1.25rem' } },
		{ title: 'Countries that have signed up for CbCR', 
			sx: { ...cardConfigSx, height: '13rem', color: 'rgb(0, 26, 72)',paddingTop:'0rem', backgroundImage: `url(${newcbcrMap})`,backgroundSize: '26vw 23vh', backgroundRepeat: 'no-repeat',
					backgroundPosition:'-2vw 2vw'},
			contentsx: { ...contentConfigSx, height: '3rem', alignItems: 'center', display: 'flex', fontSize: '1.25rem' }},
		
	]

	const thirdColumn = [
		{ title: 'Resource center', sx: { ...cardConfigSx, backgroundColor: 'rgb(0, 26, 72)', color: 'rgb(255, 255, 255)' }, contentsx: { ...contentConfigSx, height: '6rem', alignItems: 'center', display: 'flex'}},
		{ title: 'Outside resource links', 
			sx: { ...cardConfigSx, backgroundColor: 'rgb(255, 255, 255)', color: 'rgb(0, 26, 72)' }, 
			contentsx: { ...contentConfigSx, fontSize: '1.25rem' },
			type: 'hyperlink'
		}
	]
	
	return (
		<React.Fragment>
			<Box className='container'>
                <Typography
                    sx={ headerSX }
                    component='h3'>
                    <span style={{ color: '#ff5d00',  justifyContent: 'space-between' }}>
						Hello{` `}
					</span>
					<span>
						{decoded.name} {decoded.surname}!
					</span>
                </Typography>
            </Box>
			<Box className='container' sx={{ flexDirection: 'row', justifyContent: 'space-around'}}>
				<Box sx={{ display: 'flex', flexDirection: 'column', gap: '2rem', flexBasis: '33%', alignItems: 'center'}}>
					{ contentGenerator(firstColumn) }
				</Box>

				<Box sx={{ display: 'flex', flexDirection: 'column', gap: '2rem', flexBasis: '33%', alignItems: 'center'}}>
					{ contentGenerator(secondColumn) }
				</Box>

				<Box sx={{ display: 'flex', flexDirection: 'column', gap: '2rem', flexBasis: '33%', alignItems: 'center'}}>
					{ contentGenerator(thirdColumn) }
				</Box>
			</Box>
		</React.Fragment>
	);

}

export default Dashboard;
