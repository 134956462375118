import React from 'react';
import Avatar from '@mui/material/Avatar';

const UserAvatar = (props) => {

	const {text} = props;

  return (

	<Avatar {...props}>
		{text}
	</Avatar>

  );

}

export default UserAvatar;
