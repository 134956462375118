import React, {
	useState,
	useEffect,
	useContext
} from 'react';

import {
	Routes,
	useNavigate,
	useParams
} from 'react-router-dom';

import {
	TableCell,
	TableRow,
	CardHeader
} from '@mui/material';

import { AppPermissionsContext } from '../../components/common/app-context/app-permission-context';
import { getDataSourcesJson, getDataSourcesYearsByCompanyId} from '../../services/api-services';
import TableContent from '../../components/common/pages/table-content-components';
import FormControlComponent from '../../components/common/form-control-component/form-control-component';
import * as ROUTES from '../../config/routes';

const screen = {
	type: 'Data Sources',
	tabs: {
		defaultTab : 'Reports',
		disableAll: true,
		groupings: [{name: 'Reports'}, {name: 'Entities'}, {name: 'Additional Info'}], 
	},
	heading: {
		title: 'Data Sources',
		description: 'Information below represents the CbCR data',
		component: <React.Fragment>
			<CardHeader title='Currency: ZAR'
			sx={{
				display: 'flex',
				alignItems: 'center',
				padding: '16px',
				'& .MuiCardHeader-title': {
					fontSize: '16px',
					fontWeight: 700
				}
			}} />
		</React.Fragment>,
	},
	search: {
		type:'text',
		placeholder:'Search by country, registered name, etc',
		fullWidth:true,
		size:'small'
	},
	tableHead: [
		'Country',
		'Registered Name',
		'Revenue unrelated',
		'Revenue related',
		'Revenue total',
		'Profit/Loss before income tax',
		'Income tax paid',
		'Income tax accrued',
		'Stated capital',
		'Accumulated earnings',
		'Number of employees',
		'Tangible Assets'
	],
	
}

const DataSourceShow = () => {
	const navigate = useNavigate();

	const { id } = useParams();
	const [year, setYear] = useState(0);
	const [years, setYears] = useState([{
		id: '1',
		name: 'test',
	}]);
	const [periodicData, setPeriodicData] = useState();
	const { storedUser, decoded } = useContext(AppPermissionsContext);

	const [reports, setReports] = useState();
	const [entities, setEntities] = useState();
	const [additionalInfo, setAdditionalInfo] = useState();
	const [tab, setTab] = useState();
	screen.setTab = setTab;

	useEffect(() => {
		getDataSourcesYearsByCompanyId(storedUser, id)
			.then(response => {
				setYears(response.data.map(f=> {
					const currentMap = {
						id: f.id,
						name: f.year,
						company: f.company
					};

					if(f.id === id){
						setYear(currentMap);
					}
					return currentMap;
				}))
			});

		getDataSourcesJson(storedUser, id)
			.then(response => {
				setPeriodicData(response.data);
			});

	}, [navigate, id]);

	useEffect(() => {
		if(periodicData){
			setReports(periodicData.listOfReports)
			setEntities(periodicData.listOfEntities)
			setAdditionalInfo(periodicData.listOfAdditionalInfos)
		}
	}, [periodicData])

	useEffect(()=> {
		if(year && year.id !== id){
			setPeriodicData(undefined);
			navigate(`${ROUTES.DATA_SOURCE_SHOW}/${year.id}`)
		}
	}, [year])

	const filterOptions = (obj, _, searchValue) => {
		return true;
	}

	const getCurrentEntity = (code) => entities.find(f=> f.countryCode == code);

	const tableBodyMapperReports = (f, index) => {
		const currentEntity = getCurrentEntity(f.countryCode)
		return <TableRow key={`${f.countryCode}_${index}_row`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
			<TableCell key={`${f.countryCode}_${index}_countryCode`}>{f.countryCode}</TableCell>
			<TableCell key={`${f.countryCode}_${index}_tradingName`}>{ currentEntity?.constituentEntity?.tradingName }</TableCell>
			<TableCell key={`${f.countryCode}_${index}_unrelated`}>{new Intl.NumberFormat().format(f.revenue?.unrelated)}</TableCell>
			<TableCell key={`${f.countryCode}_${index}_related`}>{new Intl.NumberFormat().format(f.revenue?.related)}</TableCell>
			<TableCell key={`${f.countryCode}_${index}_total`}>{new Intl.NumberFormat().format(f.revenue?.total)}</TableCell>
			<TableCell key={`${f.countryCode}_${index}_profitOrLossBeforeIncomeTax`}>{new Intl.NumberFormat().format(f.summary?.profitOrLossBeforeIncomeTax)}</TableCell>
			<TableCell key={`${f.countryCode}_${index}_incomeTaxPaid`}>{new Intl.NumberFormat().format(f.summary?.incomeTaxPaid)}</TableCell>
			<TableCell key={`${f.countryCode}_${index}_incomeTaxAccrued`}>{new Intl.NumberFormat().format(f.summary?.incomeTaxAccrued)}</TableCell>
			<TableCell key={`${f.countryCode}_${index}_statedCapital`}>{new Intl.NumberFormat().format(f.summary?.statedCapital)}</TableCell>
			<TableCell key={`${f.countryCode}_${index}_accumulatedEarnings`}>{new Intl.NumberFormat().format(f.summary?.accumulatedEarnings)}</TableCell>
			<TableCell key={`${f.countryCode}_${index}_numberOfEmployees`}>{new Intl.NumberFormat().format(f.summary?.numberOfEmployees)}</TableCell>
			<TableCell key={`${f.countryCode}_${index}_assets`}>{new Intl.NumberFormat().format(f.summary?.assets)}</TableCell>
		</TableRow>
	}

	const tableBodyMapperEntities = (f, index) => {
		return <TableRow key={`${f.countryCode}_${index}_row`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
			<TableCell key={`${f.countryCode}_${index}_countryCode`}>{f.countryCode}</TableCell>
			<TableCell key={`${f.countryCode}_${index}_registeredName`}>{ f?.constituentEntity?.registeredName}</TableCell>
			<TableCell key={`${f.countryCode}_${index}_tradingName`}>{ f?.constituentEntity?.tradingName}</TableCell>
			<TableCell key={`${f.countryCode}_${index}_companyRegistrationNumber`}>{ f?.constituentEntity?.companyRegistrationNumber}</TableCell>
			<TableCell key={`${f.countryCode}_${index}_issuedByCountry1`}>{ f?.constituentEntity?.issuedByCountry1}</TableCell>
			<TableCell key={`${f.countryCode}_${index}_taxReferenceNumber`}>{ f?.constituentEntity?.taxReferenceNumber}</TableCell>
			<TableCell key={`${f.countryCode}_${index}_incorporatedCountryCode`}>{ f?.constituentEntity?.incorporatedCountryCode}</TableCell>
			<TableCell key={`${f.countryCode}_${index}_addressType`}>{ f?.address?.addressType}</TableCell>
			<TableCell key={`${f.countryCode}_${index}_address`}>{ f?.address?.address}</TableCell>
			<TableCell key={`${f.countryCode}_${index}_mainBusinessActivities`}>{ f?.businessActivities?.mainBusinessActivities}</TableCell>
			<TableCell key={`${f.countryCode}_${index}_otherBusinessActivity`}>{ f?.businessActivities?.otherBusinessActivity}</TableCell>
		</TableRow>
	}

	const tableBodyMapperAdditionalInfo = (f, index) => {
		return <TableRow key={`${f.otherInfo}_${index}_row`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
			<TableCell key={`${f.otherInfo}_${index}_otherInfo`}>{f.otherInfo}</TableCell>
			<TableCell key={`${f.otherInfo}_${index}_countryCode`}>{f.countryCodes}</TableCell>
			<TableCell key={`${f.otherInfo}_${index}_summaryRefCodes`}>{f.summaryRefCodes}</TableCell>
		</TableRow>
	}

	const getContent = (obj, body, filter, options) => { return { objects: obj,  body, filterOptions: filter, screen: options }};

	const mapObjects = (
		tab == 'Entities' ? getContent(entities, tableBodyMapperEntities, filterOptions, {
			...screen,
			tableHead: [
				'Country',
				'Registered name',
				'Trading name',
				'Registration number',
				'Issued by country',
				'Tax reference number',
				'Incorporated country',
				'Address type',
				'Address',
				'Main business activities',
				'Other business activities',
			],
			tabs: {
				...screen.tabs, 
				component: <FormControlComponent data={{defaultValue: 'Select Year', label: 'Select Year', name: 'id', type: 'selectbox', sx:{'width': '15rem'}, formData: year, setFormData: setYear, options: years}}/>
			}
		}) : 
		tab == 'Additional Info' ? getContent(additionalInfo, tableBodyMapperAdditionalInfo, filterOptions, {
			...screen,
			tableHead: [
				'Description',
				'Tax jurisdiction(s)',
				'Summary ref code(s)',
			],
			tabs: {
				...screen.tabs, 
				component: <FormControlComponent data={{defaultValue: 'Select Year', label: 'Select Year', name: 'id', type: 'selectbox', sx:{'width': '15rem'}, formData: year, setFormData: setYear, options: years}}/>
			}
		}) :
		getContent(reports, tableBodyMapperReports, filterOptions, {
			...screen,
			tabs: {
				...screen.tabs, 
				component: <FormControlComponent data={{defaultValue: 'Select Year', label: 'Select Year', name: 'id', type: 'selectbox', sx:{'width': '15rem'}, formData: year, setFormData: setYear, options: years}}/>
			}
		})
	);

	return periodicData && reports && entities && additionalInfo && (
	<React.Fragment>
			<TableContent key='analystic-console-table-component' data={mapObjects}/>
	</React.Fragment>
	);

}

export default DataSourceShow;