

export const BASEPATH = process.env.SERVER_URL ?? 'https://graphenetechplatform2.azurewebsites.net';
// export const BASEPATH = 'https://graphenetechplatform2.azurewebsites.net'

export const LOGIN = `${BASEPATH}/auth/sign-in`;
export const AUTH = `${BASEPATH}/auth`;
export const CLIENTS = `${BASEPATH}/client`;
export const DATA_SOURCES = `${BASEPATH}/data-source`;
export const ANALYTICS_CONSOLE = `${BASEPATH}/analytics-console`;
export const REPORTS = `${BASEPATH}/report`;
export const ROLES = `${BASEPATH}/role`;
export const PERMISSION = `${BASEPATH}/permission`;
export const ROLEPERMISSION = `${BASEPATH}/rolepermission`;
