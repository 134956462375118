import * as ROUTES from '../config/routes';

import PeopleOutlineTwoToneIcon from '@mui/icons-material/PeopleOutlineTwoTone';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import AssessmentIcon from '@mui/icons-material/Assessment';
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';
import ApartmentTwoToneIcon from '@mui/icons-material/ApartmentTwoTone';

const button = {
    size: 'large',
    iconStyle : {
        color: 'rgba(255, 255, 255, 0.3)',
        fontSize: '1.25rem',
        marginRight: '9px'
    },
    style: {
        display: 'flex',
        color: 'rgba(255, 255, 255, 0.7)',
        width: '100%',
        justifyContent: 'flex-start',
    },
    sx : {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        boxSizing: 'border-box',
        tapHighlightColor: 'transparent',
        backgroundColor: 'transparent',
        outline: '0px',
        border: '0px',
        margin: '0px',
        cursor: 'pointer',
        userSelect: 'none',
        verticalAlign: 'middle',
        appearance: 'none',
        textDecoration: 'none',
        fontFamily: 'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
        fontSize: '0.875rem',
        lineHeight: '1.75',
        minWidth: '64px',
        borderRadius: '10px',
        transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        color: 'rgb(36, 66, 175)',
        fontWeight: 'bold',
        textTransform: 'none',
        padding: '9px 16px',
        ':hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.06)',
            color: 'rgb(255, 255, 255)',
            ':before': {
                transform: 'scale(1)',
                opacity: 1,
            }
        },
        'before': {
            content: '" "',
            background: 'rgb(255, 255, 255)',
            opacity: 0,
            transition: 'transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            width: '6px',
            height: '6px',
            transform: 'scale(0)',
            transformOrigin: 'center center',
            borderRadius: '20px',
            marginRight: '16.2px'
        }
    },
    variant: 'text'
}

const HasPermission = (permissions, area, action) => {
    return permissions && permissions[area] && permissions[area][action];
}

const navItems = (permissions) => [
    {
        icon:(<HomeTwoToneIcon style={button.iconStyle}/>),
        order: 0,
        visible: true,
        route: ROUTES.ADMIN_DASHBOARD,
        title: 'Home',
        ...button
    },
    {
        icon:(<ApartmentTwoToneIcon style={button.iconStyle}/>),
        order: 1,
        visible: HasPermission(permissions, 'Clients', 'read'),
        route: ROUTES.ADMIN_CLIENTS,
        title: 'Clients',
        ...button
    },
    {
        icon:(<PeopleOutlineTwoToneIcon style={button.iconStyle}/>),
        order: 2,
        visible: HasPermission(permissions, 'Users', 'read'),
        route: ROUTES.ADMIN_USERS,
        title: 'Users',
        ...button
    },
    {
        icon:(<PeopleOutlineTwoToneIcon style={button.iconStyle}/>),
        order: 3,
        visible: HasPermission(permissions, 'Roles', 'read'),
        route: ROUTES.ADMIN_ROLES,
        title: 'Roles',
        ...button
    },
    {
        icon:(<PeopleOutlineTwoToneIcon style={button.iconStyle}/>),
        order: 4,
        visible: HasPermission(permissions, 'Permissions', 'read'),
        route: ROUTES.ADMIN_PERMISSION,
        title: 'Permissions',
        ...button
    },
    {
        icon:(<FilePresentIcon style={button.iconStyle}/>),
        order: 5,
        visible: HasPermission(permissions, 'Data Sources', 'read'),
        route: ROUTES.DATA_SOURCE,
        title: 'Data Sources',
        ...button
    },
    {
        icon:(<AssessmentIcon style={button.iconStyle}/>),
        
        order: 6,
        visible: true,
        route: ROUTES.ANALYTIC_CONSOLES,
        title: 'Analytics Console',
        ...button
    },
    // {
    //     icon:(<ApartmentTwoToneIcon style={button.iconStyle}/>),
    //     order: 7,
    //     visible: true,
    //     route: ROUTES.ADMIN_CLIENTSV2,
    //     title: 'Clients',
    //     ...button
    // },
];

export default navItems;