import React, {
	useState,
	useEffect,
	useContext
} from 'react';

import {
	useNavigate
} from 'react-router-dom';

import {
	Box,
	IconButton,
	Typography,
	TableCell,
	TableRow,
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';

import { AppPermissionsContext } from '../common/app-context/app-permission-context';
import TableContent from '../common/pages/table-content-components';

const TableComponent = (props) => {
    const {
		data: {
			edit, 
            loadData,
            objects,
            remove,
            screen,
		},
		children
	} = props;

	const navigate = useNavigate();

	useEffect(() => {
		loadData();
	}, [navigate]);

	const actions = (f) => {
		return <TableCell key={`${f.id}_actions`}>
				 <Typography key={`${f.id}_typo`}>
					{ 
						edit && <IconButton 
									key={`${f.id}_edit`} 
									onClick={(e) => edit.click(e, f)}>
								<EditTwoToneIcon />
							</IconButton>
					}
					{ 
						remove && <IconButton 
										key={`${f.id}_delete`} 
										onClick={(e) => remove.click(e, f)}>
									<DeleteTwoToneIcon />
								</IconButton>
					}
				</Typography>
			</TableCell>
	}

	const filterOptions = (obj, _, searchValue) => {
		return Object.keys(obj).map(g=> g != 'id' && typeof obj[g] === 'string' && obj[g].toLowerCase().includes(searchValue.toLowerCase())).find(f=> f == true);
	}

	const tableBodyMapper = (f) => (
		<TableRow key={`${f.id}_row`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
			<TableCell key={`${f.id}_name`}>{f.name}</TableCell>
			<TableCell key={`${f.id}_description`}>{f.description}</TableCell>
			{
				actions(f)
			}
		</TableRow>
	);

	return (
		<React.Fragment>
			<TableContent data={{
				objects, 
				body: tableBodyMapper,
				filterOptions,
				screen
			}}>
			</TableContent>
		</React.Fragment>
	);
}

export default TableComponent;