
import React, {
	useEffect,
	useContext,
	useState
} from 'react';

import {
	Box,
	Divider,
	Typography,
	Grid,
	Button,
	Paper,
	FormControl,
	DialogContent,
	DialogActions,
	TextField,
	Select,
	MenuItem,
	InputLabel,
	Menu,
	CardContent
} from '@mui/material';

import { AppPermissionsContext } from '../../components/common/app-context/app-permission-context';
import FormControlComponent from '../../components/common/form-control-component/form-control-component';
import ContentComponent from '../../components/common/content-component';
import WorldMap from '../../components/map';
import { AppContext } from '../../utils/app-context';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { getEmbedConfig, getSourceDataDetailsById, getAnalyticsConsoleById } from '../../services/api-services';
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone';
import ArrowForwardIosTwoToneIcon from '@mui/icons-material/ArrowForwardIosTwoTone';

import {Route, Link, Routes, useParams, useNavigate} from 'react-router-dom';

import { getAnalyticConsoleDataDetailsByCompany } from '../../services/api-services';
import * as ROUTES from '../../config/routes';

const analysticsConsoleMap = (overviewData) => [
	{
		title: 'ZAR',
		value: 'CURRENCY',
	},
	{
		title: overviewData?.totalTaxJurisdictions,
		value: 'TAX JURISDICTIONS',
	},
	{
		title: overviewData?.totalEntities,
		value: 'ENTITIES',
	},
	{
		title: overviewData?.totalEmployees,
		value: 'EMPLOYEES',
	},
	{
		title: new Intl.NumberFormat().format(overviewData?.totalGroupRevenue),
		value: 'GROUP REVENUE',
	},
	{
		title: new Intl.NumberFormat().format(overviewData?.totalGroupProfit),
		value: 'GROUP PROFIT',
	},
	{
		title: overviewData?.significantActivity,
		value: 'SIGNIFICANT ACTIVITY',
	},
]

const AnalyticConsolePowerBi = (props) => {
	const navigate = useNavigate();
	const {
		data:{
			overview,
			consoleData
		}
	} = props;

	const [powerBiConfig, setPowerBiConfig] = useState({});
	const [report, setReport] = useState([]);
	const [reportLoaded, setReportLoaded] = useState(false);
	const [reportRendered, setReportRendered] = useState(false);
	const [pages, setPages] = useState([]);
	const [pageActiveIndex, setPageActiveIndex, pageActiveIndexRef] = useState(0);
	const [activeReportTab, setActiveReportTab] = useState({});
	const [object, setObject] = useState({});
	const { storedUser, } = useContext(AppPermissionsContext);

	useEffect(() => {
		if (reportLoaded && report?.config?.id) {
			report.getPages().then((response) => {
				response[0].setActive();	
				setPages(response);
				setActiveReportTab(response[0])
			});
		}
	}, [reportLoaded, report, navigate]);

	useEffect(() => {
		getEmbedConfig(storedUser, consoleData.reportId)
			.then((response) => {
				setPowerBiConfig(response.data)})
			.catch((error) => console.log(error));
		if(pages && pages.length > 0 && overview){
			setPageActiveIndex(0);
			pages[0].setActive();
		}
	}, [overview, navigate]);

	/**
	 * Moves to the previous page in the active report.
	 */
	const moveToPrevPage = () => {
		if (pages && pages.length > 0 && pages[pageActiveIndex - 1]) {
			setPageActiveIndex(pageActiveIndex - 1);
			pages[pageActiveIndex + 1].setActive();
		}
	}

	/**
	 * Moves to the next page in the active report.
	 */
	const moveToNextPage = () => {
		//window.report.setPage(page.id);
		if (pages && pages.length > 0 && pages[pageActiveIndex + 1]) {
			setPageActiveIndex(pageActiveIndex + 1);
			pages[pageActiveIndex + 1].setActive();
		}
	}

	return <React.Fragment>
				<div style={{ width: '98%', position: 'absolute' }}>
					{ pages && pages.length > 0 && pages[pageActiveIndex - 1] &&
						<div className='prev-next' onClick={moveToPrevPage}>
							<ArrowBackIosNewTwoToneIcon sx={{
								fontSize: '2.2em',
								'&:hover': {
									transform: 'scale(1.2)'
								}
							}} />
						</div>
					}
	
					<PowerBIEmbed
						embedConfig={{
									type: 'report',
									id: powerBiConfig.id,
									embedUrl: powerBiConfig.embedUrl,
									accessToken: powerBiConfig.token,
									permissions: models.Permissions.All,
									tokenType: models.TokenType.Embed,
									viewMode: models.ViewMode.View,
									settings: {
										panes: {
											filters: {
												expanded: false,
												visible: false
											},
											pageNavigation: {
												visible: false
											}
										},
										background: models.BackgroundType.Transparent,
									}
								}}
								eventHandlers={
									new Map([
										['loaded', () => setReportLoaded(true)],
										['rendered', () => setReportRendered(true)],
										['error', function (event, embed) { console.warn({ event, embed }); }]
									])
								}
								cssClassName={'pbi-container'}
								getEmbeddedComponent={(embeddedReport) => {
									window.report = embeddedReport;
									setReport(embeddedReport);
					}}/>

					{ pages && pages.length > 0 && pages[pageActiveIndex + 1] &&
						<div className='prev-next'
							onClick={moveToNextPage}
							style={{ right: '36px' }}>

							<ArrowForwardIosTwoToneIcon
								sx={{
									fontSize: '2.2em',
									'&:hover': {
										transform: 'scale(1.2)'
									}
								}} />

						</div>
					}
				</div>
			</React.Fragment>
}

const AnalyticCardContent = (props) => {
	const navigate = useNavigate();
	const {
		data:{
			consoleId
		}
	} = props;

	const [overviewData, setOverviewData] = useState(null);
	const [consoleData, setConsoleData] = useState(null);
	const { storedUser, permissions, decoded, overview } = useContext(AppPermissionsContext);

	useEffect(() => {
		getAnalyticsConsoleById(storedUser, consoleId)
			.then((response) => {
				setConsoleData(response.data);
			})
	}, [navigate]);

	useEffect(() => {
		if(consoleData){
			getSourceDataDetailsById(storedUser, consoleData.file)
				.then((response) => {
					setOverviewData(response.data)
				})
		}
	}, [consoleData, navigate]);	

	const mergeMapData = () => {
		var result = [];
		overviewData.mapData.reduce((res, val) =>{
			if (!res[val.id]) {
				res[val.id] = { id: val.id, value: val.value, capital: val.capital, employees: val.employees };
				result.push(res[val.id])
			}
			res[val.id].value += val.value;
			res[val.id].capital += val.capital;
			res[val.id].employees += val.employees;
			return res;
		}, {});
		return result;
	}

	return consoleData && overviewData && (
		<React.Fragment>
			{overview && <React.Fragment><CardContent className='card-content-component'>
				<>
					<Box className='analytic-no-period analytic-space'>
						{analysticsConsoleMap(overviewData).map(f=>(
							<Grid item xs={12} lg={3}>
								<Paper className='analytic-no-period-content' elevation={1} variantion='rounded'>
									<Box className='analytic-no-period-content-position'>
										<Typography
											sx={{
												margin: '0px',
												fontWeight: 700,
												lineHeight: '1.167',
												fontSize: '1.0rem'
											}}>
											{f.title}
										</Typography>

										<Divider
											sx={{
												flexShrink: 0,
												border: '0px',
												width: '50px',
												background: 'rgb(255 93 0)',
												height: '6px',
												borderRadius: '50px',
												margin: '18px auto'
											}} />

										<Typography
											sx={{
												margin: '0px',
												lineHeight: '1.4',
												color: 'rgb(111 115 123)',
												fontWeight: 700,
												fontSize: '0.800rem',
												textTransform: 'uppercase',
											}}>
											{f.value}
										</Typography>
									</Box>
								</Paper>
							</Grid>
						))}

					</Box>
				</>
			</CardContent>
			{overviewData &&
					<WorldMap 
						chartID='world-map'
						data={mergeMapData()} />
				}
			<Divider sx={{'marginTop': '30px', 'marginBottom': '30px'}}/> 
			</React.Fragment>}
			{!overview && <AnalyticConsolePowerBi data={{overview, consoleData}}/>}
		</React.Fragment>);
}

const AnalyticConsoleShow = (props) => {
	const { id } = useParams();
	const params = useParams();
	const navigate = useNavigate();
	const { storedUser } = useContext(AppPermissionsContext);

	const [year, setYear] = useState(0);
	const [years, setYears] = useState([{
		id: '1',
		name: 'test',
	}]);

	useEffect(() => {
		getAnalyticConsoleDataDetailsByCompany(storedUser, id)
			.then(response => {
				setYears(response.data.map(f=> {
					const currentMap = {
						id: f.id,
						name: f.year,
						company: f.companyId
					};

					if(f.id === id){
						setYear(currentMap);
					}
					return currentMap;
				}))
			});

	}, [navigate]);

	useEffect(() => {
		if(year && year.id !== id){
			navigate(`${ROUTES.ANALYTIC_CONSOLES}/show/${year.id}`)
		}
	}, [year, navigate]);

	return (
			<React.Fragment>
				<ContentComponent className='analystic-console-component' data={{
					title: 'Analytics Console',
					description: '',
					component: <FormControlComponent data={{defaultValue: 'Select Year', label: 'Select Year', name: 'id', type: 'selectbox', sx:{'width': '15rem'}, formData: year, setFormData: setYear, options: years}}/>
				}}>
					<AnalyticCardContent data={{consoleId: params.id}}/>
				</ContentComponent>
			</React.Fragment>
	);
}

export default AnalyticConsoleShow;
