const divText = {
    color: { color: 'rgba(34, 51, 84, 0.7)' },
    fontSize: '15px',
    fontWeight: '400',
    margin: '0px',    
}


const color = { color: 'rgb(255, 255, 255)' };
const marginLeft = { marginLeft: '-36px' }
const marginTop = { marginTop: '2rem' }
const width = { width: 'calc(100%)' };

const h4 =  {
    margin: '0px 0px 4px',
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '1.235',
};


const body = {
    color,
}

const container = {
    ...body,
    marginLeft,
    marginTop,
    width,
}

const globalPaper = {
    backgroundColor: 'rgb(255, 255, 255)',
    color: 'rgb(34, 51, 84)',
    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    borderRadius: '10px',
    padding: '0px',
    boxShadow: 'rgb(159 162 191 / 18%) 0px 9px 16px, rgb(159 162 191 / 32%) 0px 2px 2px',
    overflow: 'hidden',
    width: '100%'
};

export {
    body,
    container,
    globalPaper,
    h4,
    divText,
};