import {
	Button,
	ListItem,
} from '@mui/material';

import {
	useNavigate
} from 'react-router-dom';

const MenuListItem = (props) => {
    const navigate = useNavigate();
    const {
        icon,
        route,
        style,
        size,
        sx,
        title,
        variant
    } = props;

    return (
        <ListItem key={route}>
            <Button key={route}
                style={style}
                sx={sx}
                size={size}
                variant={variant}
                onClick={() => navigate(route)}
                startIcon={icon}>
                {title}
            </Button>
        </ListItem>
    )
};

export default MenuListItem;