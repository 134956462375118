const headerSX = {
    margin: '0px 0px 4px',
    fontWeight: 700,
    fontSize: '25px',
    lineHeight: '1.4',
    color: 'rgb(34, 51, 84)',
    fontFamily: 'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
};
const descriptionSX = {
    margin: '0px',
    fontWeight: 400,
    fontSize: '15px',
    color: 'rgba(34, 51, 84, 0.7)',
    fontFamily: 'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
    lineHeight: '1.57',
}
export {
    descriptionSX,
    headerSX
};