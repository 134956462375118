import {
	List,
} from '@mui/material';

import MenuListItem from './menu-item';
import navItems from '../../../services/menu-service';
import { AppPermissionsContext } from '../app-context/app-permission-context';

import React, {
	useContext
} from 'react';

const GetMenuListItem = (permissions) => {
    return navItems(permissions).map(f =>{
        if(f.visible){
            return <MenuListItem key={f.route} {...f}></MenuListItem>
        }
        return;
    });
}

const MenuList = () => {

    const { permissions } = useContext(AppPermissionsContext);
    return (<List sx={{padding: '9px'}}>
        {
            GetMenuListItem(permissions)
        }
    </List>);
}

export default MenuList;