
import React from 'react';

import {
    Box,
    Table,
	TableContainer,
} from '@mui/material';

import TableHeadComponent from './table-head-component';
import TableBodyComponent from './table-body-component';


const TableComponent = (props) => {
    const {
        data: {
            tableHeadComponents,
            tableBodyComponents,    
        } = {},
        children
    } = props;

    return (
        <React.Fragment>
            <TableContainer className='generic-table-container' key={'tableContainer'} sx={{
							width: '100%',
							overflowX: 'auto'
						}}>
                <Box sx={{padding: '1.5rem'}}>
                <Table className='generic-table-content' key={'table'}
                    sx={{
                        display: 'table',
                        width: '100%',
                        borderCollapse: 'collapse',
                        borderSpacing: '0px',
                        fontSize: '0.8rem'
                    }}>
                        {(!children &&
                            <React.Fragment>
                                <TableHeadComponent key={'tableHead'}>
                                    {tableHeadComponents}
                                </TableHeadComponent>
                                <TableBodyComponent key={'tablebody'}>
                                    {tableBodyComponents}
                                </TableBodyComponent>
                            </React.Fragment>) || children
                        }
                </Table>
                </Box>
            </TableContainer>
    </React.Fragment>)
}

export default TableComponent;