
import {
	useEffect,
	useLayoutEffect,
	useRef
} from 'react';

import * as am5 from '@amcharts/amcharts5';
import * as am5map from '@amcharts/amcharts5/map';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import am5geodata_worldLow from '@amcharts/amcharts5-geodata/worldHigh';

const WorldMap = (props) => {

	const {
		chartID,
		data
	} = props;

	const previousMapData = useRef(data);
	const chartRef = useRef(null);
	const activeDuplicatedCountries = data.map((country) => country.id);
	const activeCountries = activeDuplicatedCountries.filter((dup, ind) => activeDuplicatedCountries.indexOf(dup) === ind );

	useLayoutEffect(() => {

		const root = am5.Root.new(chartID);
		root.setThemes([am5themes_Animated.new(root)]);

		let chart = root.container.children.push(

			am5map.MapChart.new(root, {
				projection: am5map.geoMercator(),
				panX: false,
				panY: false,
				wheelX: false,
				wheelY: false
			})

		);

		const worldSeries = chart.series.push(am5map.MapPolygonSeries.new(root, {
			geoJSON: am5geodata_worldLow,
			exclude: ['AQ']
		}));

		worldSeries.mapPolygons.template.setAll({
			fill: am5.color('rgb(234, 229, 217)')
		});

		const polygonSeries = chart.series.push(

			am5map.MapPolygonSeries.new(root, {
				geoJSON: am5geodata_worldLow,
				exclude: ['AQ'],
				include: activeCountries,
				calculateAggregates: true
			})
			
		);

		polygonSeries.mapPolygons.template.setAll({
			tooltipText: '[bold]{name}[/]\n [bold]Capital:[/] {capital}\n [bold]Revenue:[/] {value} \n [bold]Head Count:[/] {employees}',
			interactive: true
		});

		polygonSeries.mapPolygons.template.states.create('hover', {
			fill: am5.color('#FF5D00')
		});
 
		data.length && polygonSeries.data.setAll(data);

		// HEAT MAP

		polygonSeries.set('heatRules', [{
			target: polygonSeries.mapPolygons.template,
			dataField: 'value',
			min: am5.color('#6793dc'),
			max: am5.color('#112779'),
			key: 'fill'
		}]);

		chartRef.current = chart;

		return () => {
			root.dispose();
		};

	}, [chartID, data, activeCountries])

	return <div className='13' id={chartID}></div>;

}

export default WorldMap;
