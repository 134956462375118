import React, {
	useState,
	useEffect,
	useContext
} from 'react';

import {
	useNavigate
} from 'react-router-dom';

import {
	Box,
	IconButton,
	Typography,
	TableCell,
	TableRow,
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';

import { ROLES } from '../config/api';
import Footer from '../components/footer';
import ModalComponent from '../components/common/global-component/modal/modal-component';
import DialogComponent from '../components/common/global-component/dialog/dialog-component';
import ModalService from '../services/modal-service';
import { AppPermissionsContext } from '../components/common/app-context/app-permission-context';
import { getRoles, getPermissions } from '../services/api-services';
import TableContent from '../components/common/pages/table-content-components';

const screen = {
	type: 'Roles',
	heading: {
		title: 'Role Management',
		description: 'All aspects related to role can be managed from this page.'
	},
	search: {
		type:'text',
		placeholder:'Search by role.',
		fullWidth:true,
		size:'small'
	},
	tableHead: [
		'Name',
		'Description',
		''
	]
}

const Roles = () => {
	const { permissions } = useContext(AppPermissionsContext);

    const [objects, setObjects] = useState([]);
	const [perms, setPermissions] = useState([]);

	const [data, setData] = React.useState([]);

	const navigate = useNavigate();

	const loadData = async () => {
		getRoles()
		.then(response =>{
			setObjects(response.data);
		})

		getPermissions()
		.then(response => {
			setPermissions(response.data);
		});
	}

	const formControls = [
		{
			label: "Name",
			placeholder: "Role Name",
			helperText: "Role Name is required",
			required: true,
			validation: (obj) => true
		},
		{
			label: "Description",
			placeholder: "Description",
			helperText: "Description is required",
			required: true,
			validation: (obj) => true
		},
		{
			label: "Permissions",
			type: 'multiselect',
			propertyData: data,
			perms,
			setData,
			required:true
		}
	]

	const createModal = {
		form: {
			description: 'Fill in all the fields below to create a new role.',
			formControls,
			processText: 'Add new role',
			title: 'Add New Role',
			validation: () => true,
			type: 'create'
		},
		request: {
			method: 'POST',
			requestValidation: () => true,
			url: () => `${ROLES}/create`,
		}
	}

	const editModal = {
		form: {
			description: 'Fill in all the fields below to create a new role.',
			formControls,
			processText: 'Edit existing role',
			title: 'Edit Existing Role',
			validation: () => true,
			type: 'edit'
		},
		request: {
			method: 'PATCH',
			requestValidation: () => modals.edit.modalData.selected,
			url: () => `${ROLES}/${modals.edit.modalData.selected.id}`,
		}
	}

	const deleteModal = {
		form: {
			description: 'Are you sure, you know what you doing?',
			formControls: [
				{
					label: "Name",
					type: 'readonly',
					disabled: true,
				},
				{
					label: "Description",
					type: 'readonly',
					disabled: true,
				}
			],
			processText: 'Delete existing role',
			title: 'Delete Existing Role',
			validation: () => true,
			type: 'delete'
		},
		request: {
			method: 'DELETE',
			requestValidation: () => modals.delete.modalData.selected.id,
			url: () => `${ROLES}/${modals.delete.modalData.selected.id}`,
		}
	}

	const modals = {
		create: ModalService(createModal),
		edit: ModalService(editModal),
		delete: ModalService(deleteModal),
	}

	useEffect(() => {
		loadData();
	}, [navigate, modals.delete.result, modals.edit.result, modals.create.result]);

	const actions = [
		{
			icon: <AddIcon />,
			name: 'Add New Role',
			callback: modals.create.open
		}
	];

	const tableBodyMapper = (f) => (
		<TableRow key={`${f.id}_row`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
			<TableCell key={`${f.id}_name`}>{f.name}</TableCell>
			<TableCell key={`${f.id}_description`}>{f.description}</TableCell>

			<TableCell key={`${f.id}_actions`}>
				<Typography key={`${f.id}_typo`}>
					{ permissions.Roles.delete && <IconButton key={`${f.id}_edit`}
						onClick={(e) => modals.edit.open(e, f)}>
						<EditTwoToneIcon />
					</IconButton>
					}
					{ permissions.Roles.delete && <IconButton key={`${f.id}_delete`}
						onClick={(e) => modals.delete.open(e, f)}>
						<DeleteTwoToneIcon />
					</IconButton>
					}
				</Typography>
			</TableCell>
		</TableRow>
	);

	const getModalComponent = (modalData) => {
		const validation = modalData && modalData.validation && modalData.validation();
		return (validation && <ModalComponent key={modalData.type} data={modalData}>
			<Box>
				<form noValidate autoComplete='off'>
					{
						<DialogComponent key={modalData.type} data={{...modalData, defaultValue: modalData.selected}}></DialogComponent>
					}
				</form>
			</Box>
		</ModalComponent>
	)};

	const filterOptions = (obj, _, searchValue) => {
		return obj.name.toLowerCase().includes(searchValue.toLowerCase()) ||
		obj.description.toLowerCase().includes(searchValue.toLowerCase())
	}

	return (
		<React.Fragment>
			<TableContent data={{
				objects, 
				body: tableBodyMapper,
				filterOptions,
				screen
			}}>
				<Footer actions={ permissions.Users.create && actions} />
				{
					[
						getModalComponent(modals.create.modalData), 
						getModalComponent(modals.edit.modalData),
						getModalComponent(modals.delete.modalData)
					]
				}
			</TableContent>
		</React.Fragment>
	);
}

export default Roles;