import { AppPermissionsContext } from '../app-context/app-permission-context';
import {
  Navigate,
} from 'react-router-dom';

import React, {
	useContext
} from 'react';
import * as ROUTES from '../../../config/routes';


export const ProtectedRoute = (props) => {
    const {
      data: {
        action,
        area,
        redirectPath = ROUTES.ROOT,
      },
      children,
    } = props;

    const { permissions } = useContext(AppPermissionsContext);   
    
    if (!permissions || !permissions[area] || !permissions[area][action]) {
      return <Navigate to={redirectPath} replace/>;
    }

    return children;
  };