
import React, { useState } from 'react';

import {
	Box,
	Typography,
	createTheme,
	ThemeProvider,
	Container,
} from '@mui/material';

const Loading = props => {
	const mdTheme = createTheme();
	return (

		<ThemeProvider theme={mdTheme}>
			<Box>
				<Container>
					<Box>
						<Typography>
							<strong>Loading!</strong>
						</Typography>
					</Box>
				</Container>
			</Box>
		</ThemeProvider >

	);

}

export default Loading;
