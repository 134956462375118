
import React from 'react';
import {
    Box,
	Typography
} from '@mui/material';
import { headerSX, descriptionSX} from './style-constant'
const HeaderComponent = (props) => {
    const {
        data:{
            title,
            description
        },
        children
    } = props;
 
    return (
        <React.Fragment>
            <Box>
                <Typography
                    sx={ headerSX }
                    component='h3'>
                    { title }
                </Typography>

                <Typography
                    sx={ descriptionSX }
                    variant='subtitle2'
                    component='div'>
                    { description }
                </Typography>
            </Box>
            {children}
        </React.Fragment>
    )
};

export default HeaderComponent;