
import React from 'react';
import {
    TextField,
    InputLabel,
    Select,
    MenuItem
} from '@mui/material';

import { collectFormData } from './form-control-helper-component';

const getProperty = (obj, keys) => {
    return keys.reduce((f, i)=> {
        if(f[i]){
            return f[i];
        }
        return obj;
    }, obj);
}

const FormControlSelectComponent = (props) => {
    const {
        data:{
            defaultValue,
            formData,
            label,
            name,
            options = [],
            required,
            disabled = false
        }
    } = props;

    const fieldName = (name ?? label).toLowerCase()?.split('.');

    return (
        <React.Fragment>
             <InputLabel id={`${label}Label`}>
                {label}
            </InputLabel>

            <Select
                disabled={disabled}
                required={required}
                label={label}
                labelId={`${label}Label`}
                id={fieldName}
                value={formData && getProperty(formData, fieldName) || defaultValue && defaultValue[fieldName] || ""}
                onChange={(e) => collectFormData(props ,fieldName, e.target.value)}>
                
                {options && options.map((option) => {
                    return <MenuItem key={option.name} value={option.id}>
                        {option.name}
                    </MenuItem>
                })}
            </Select>
        </React.Fragment>
    )
};

export default FormControlSelectComponent;