
import React, {
	useState,
	useEffect,
	useContext
} from 'react';

import {
	Divider,
	Paper,
	TableCell,
} from '@mui/material';

import { AppPermissionsContext } from '../app-context/app-permission-context';
import ContentComponent from '../content-component';
import { globalPaper } from '../style-constants';
import SearchComponent from '../search/search-component';
import TabList from '../tab/tab-list';
import TableComponent from '../global-component/table/table-component';

const TableContent = (props) => {
    const {
		data: {
			area = 'Users',
			body,
			objects,
			groupings,
			filterOptions,
            screen
		},
		children
	} = props;

	const { permissions } = useContext(AppPermissionsContext);
	const [filtered, setFiltered] = useState([]);
	const [tab, setTab] = useState(screen.tabs?.defaultTab ?? 'All');	
	const [searchValue, setSearchValue] = useState('');

	useEffect(() => {
		filterData(tab, searchValue);
	}, [objects, tab, searchValue]);

	useEffect(() => {
		if(screen.setTab){
			screen.setTab(tab);
		}
	}, [tab]);

	const filterData = (tab, searchValue) => { setFiltered(objects.filter(f=> filterOptions(f, tab, searchValue))) };

	return permissions && permissions[area] && (
		<React.Fragment>
			<ContentComponent data={screen.heading}>
				<TabList setTab={setTab} value={tab} groupings={groupings ?? screen.tabs?.groupings} disableAll={screen.tabs?.disableAll} component={screen.tabs?.component}/>
				<Paper elevation={1} sx={globalPaper}>
					<SearchComponent data={screen.search} setSearchTerm={setSearchValue}/>
					<Divider/>
					<TableComponent key={'tableComponent'} data={
						{
							tableHeadComponents : screen.tableHead.map(f=><TableCell key={f}>{f}</TableCell>),
							tableBodyComponents : filtered && filtered.length > 0 && filtered.map((f, index)=>body(f, index))
						}
					}>
					</TableComponent>
					
				</Paper>
				{children}
			</ContentComponent>
		</React.Fragment>
	)
};

export default TableContent;