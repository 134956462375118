
import React from 'react';

import {
	TableHead,
	TableRow,
} from '@mui/material';

const TableHeadComponent = (props) => {
    const {
        children
    } = props;

    const tableHeadSx = {
        display: 'table-header-group',
        '& .MuiTableCell-head': {
            fontWeight: 700
        }
    };

    return (<React.Fragment>
        <TableHead key={'tableHead'} sx={tableHeadSx}>
            <TableRow key={'row'}>
                {children}
            </TableRow>
        </TableHead>
    </React.Fragment>)
}

export default TableHeadComponent;