
import React from 'react';

import { nav } from '../../utils/nav.js';
import { Outlet } from "react-router-dom";

import {
	Box,
	IconButton,
	Divider,
	Badge,
	Link,
	createTheme,
	ThemeProvider,
	Typography,
	Button,
	Menu,
	MenuItem,
	DialogContent,
	DialogActions,
	ListItemText
} from '@mui/material';
import ChevronRightTwoToneIcon from '@mui/icons-material/ChevronRightTwoTone';
import Report from '../report/report';

const ContentComponent = (props) => {
    const {
            prop: {
            anchorEl,
            anchorEl2,
            anchorEl3,
            setAnchorEl,
            setAnchorEl2,
            setAnchorEl3,
            handleNavSelection,
            selectedMenuIndex,
            selectedMenuIndex2,
            selectedMenuIndex3,
        }
    } = props

    const handleNavClose = () => {
        setAnchorEl(null);
        setAnchorEl2(null);
        setAnchorEl3(null);
    };

    const open = Boolean(anchorEl);
	const open2 = Boolean(anchorEl2);
	const open3 = Boolean(anchorEl3);

    return (
        <React.Fragment>
            {/* MAIN CONTENT AREA */}
					<Box className='content-wrapper'
						sx={{
							position: 'relative',
							zIndex: 5,
							marginLeft: { 'lg': '290px' },
							display: 'block',
							flex: '1 1 0%',
							paddingTop: '80px',
							height: 'calc(100vh - 80px)',
							background: '#f8f8f8'
						}}>

						<Outlet />

					</Box>

					<Menu
						anchorEl={anchorEl}
						open={open}
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'left',
						}}
						onClose={handleNavClose}
						MenuListProps={{}}
						sx={{
							'&  .MuiMenuItem-root': {
								borderRadius: '50px',
								padding: '9px 9px 9px 22.5px',
								minWidth: '350px',
								marginBottom: '2px',
								position: 'relative',
								color: 'rgba(34, 51, 84, 0.7)',
							},
							'&  .MuiMenuItem-root:hover': {
								borderRadius: '50px',
								padding: '9px 9px 9px 22.5px',
								minWidth: '350px',
								marginBottom: '2px',
								position: 'relative',
								color: 'rgba(34, 51, 84, 0.7)',
							},
							'&  .MuiMenuItem-root:active': {
								borderRadius: '50px',
								padding: '9px 9px 9px 22.5px',
								minWidth: '350px',
								marginBottom: '2px',
								position: 'relative',
								color: 'rgba(34, 51, 84, 0.7)',
							}
						}}>

						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-between',
								padding: '18px',
								background: 'rgb(242 243 245)',
								margin: '-8px 0 0'
							}}>

							<Box>

								<Typography
									variant='h4'
									component='div'
									sx={{
										margin: '0px',
										fontWeight: 700,
										fontFamily: 'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
										fontSize: '16px',
										lineHeight: 1.235,
										paddingBottom: '4.5px',
									}}>
									Business Insights
								</Typography>

								<Typography
									noWrap
									component='div'
									variant='subTitle2'
									sx={{
										margin: '0px',
										fontWeight: 400,
										fontFamily: 'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
										fontSize: '15px',
										color: 'rgba(34, 51, 84, 0.7)',
										lineHeight: 1.57,
										overflow: 'hidden',
										textOverflow: 'ellipsis',
										whiteSpace: 'nowrap',
									}}>
									{/* Some one liner on Business Insights */}
								</Typography>

							</Box>

						</Box>

						<Divider sx={{
							margin: '0px',
							flexShrink: 0,
							background: 'rgba(34, 51, 84, 0.1)',
							border: '0px',
							height: '1px',
						}} />

						<div style={{
							display: 'flex',
							justifyContent: 'stretch',
							alignItems: 'stretch',
							flexDirection: 'column',
							padding: '20px'
						}}>

							{nav[0].children.map((option, index) => (

								<MenuItem
									key={option.name}
									selected={index === selectedMenuIndex}
									onClick={() => handleNavSelection(option)}>

									<ListItemText sx={{
										flex: '1 1 auto',
										minWidth: '0px',
										margin: '0px'
									}}>
										{option.name}
									</ListItemText>

									<ChevronRightTwoToneIcon />

								</MenuItem>

							))}

						</div>

					</Menu>

					<Menu
						anchorEl={anchorEl2}
						open={open2}
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'left',
						}}
						onClose={handleNavClose}
						MenuListProps={{}}
						sx={{
							'&  .MuiMenuItem-root': {
								borderRadius: '50px',
								padding: '9px 9px 9px 22.5px',
								minWidth: '350px',
								marginBottom: '2px',
								position: 'relative',
								color: 'rgba(34, 51, 84, 0.7)',
							},
							'&  .MuiMenuItem-root:hover': {
								borderRadius: '50px',
								padding: '9px 9px 9px 22.5px',
								minWidth: '350px',
								marginBottom: '2px',
								position: 'relative',
								color: 'rgba(34, 51, 84, 0.7)',
							},
							'&  .MuiMenuItem-root:active': {
								borderRadius: '50px',
								padding: '9px 9px 9px 22.5px',
								minWidth: '350px',
								marginBottom: '2px',
								position: 'relative',
								color: 'rgba(34, 51, 84, 0.7)',
							}
						}}>

						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-between',
								padding: '18px',
								background: 'rgb(242 243 245)',
								margin: '-8px 0 0'
							}}>

							<Box>

								<Typography
									variant='h4'
									component='div'
									sx={{
										margin: '0px',
										fontWeight: 700,
										fontFamily: 'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
										fontSize: '16px',
										lineHeight: 1.235,
										paddingBottom: '4.5px',
									}}>
									Tax Risk
								</Typography>

								<Typography
									noWrap
									component='div'
									variant='subTitle2'
									sx={{
										margin: '0px',
										fontWeight: 400,
										fontFamily: 'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
										fontSize: '15px',
										color: 'rgba(34, 51, 84, 0.7)',
										lineHeight: 1.57,
										overflow: 'hidden',
										textOverflow: 'ellipsis',
										whiteSpace: 'nowrap',
									}}>
									{/* Some one liner on Tax Risk */}
								</Typography>

							</Box>

						</Box>

						<Divider sx={{
							margin: '0px',
							flexShrink: 0,
							background: 'rgba(34, 51, 84, 0.1)',
							border: '0px',
							height: '1px',
						}} />

						<div style={{
							display: 'flex',
							justifyContent: 'stretch',
							alignItems: 'stretch',
							flexDirection: 'column',
							padding: '20px'
						}}>

							{nav[1].children.map((option, index) => (

								<MenuItem
									key={option.name}
									selected={index === selectedMenuIndex2}
									onClick={() => handleNavSelection(option)}>

									<ListItemText sx={{
										flex: '1 1 auto',
										minWidth: '0px',
										margin: '0px'
									}}>
										{option.name}
									</ListItemText>

									<ChevronRightTwoToneIcon />

								</MenuItem>

							))}

						</div>

					</Menu>

					<Menu
						anchorEl={anchorEl3}
						open={open3}
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'left',
						}}
						onClose={handleNavClose}
						MenuListProps={{}}
						sx={{
							'&  .MuiMenuItem-root': {
								borderRadius: '50px',
								padding: '9px 9px 9px 22.5px',
								minWidth: '350px',
								marginBottom: '2px',
								position: 'relative',
								color: 'rgba(34, 51, 84, 0.7)',
							},
							'&  .MuiMenuItem-root:hover': {
								borderRadius: '50px',
								padding: '9px 9px 9px 22.5px',
								minWidth: '350px',
								marginBottom: '2px',
								position: 'relative',
								color: 'rgba(34, 51, 84, 0.7)',
							},
							'&  .MuiMenuItem-root:active': {
								borderRadius: '50px',
								padding: '9px 9px 9px 22.5px',
								minWidth: '350px',
								marginBottom: '2px',
								position: 'relative',
								color: 'rgba(34, 51, 84, 0.7)',
							}
						}}>

						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-between',
								padding: '18px',
								background: 'rgb(242 243 245)',
								margin: '-8px 0 0'
							}}>

							<Box>

								<Typography
									variant='h4'
									component='div'
									sx={{
										margin: '0px',
										fontWeight: 700,
										fontFamily: 'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
										fontSize: '16px',
										lineHeight: 1.235,
										paddingBottom: '4.5px',
									}}>
									Global Comparatives
								</Typography>

								<Typography
									noWrap
									component='div'
									variant='subTitle2'
									sx={{
										margin: '0px',
										fontWeight: 400,
										fontFamily: 'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
										fontSize: '15px',
										color: 'rgba(34, 51, 84, 0.7)',
										lineHeight: 1.57,
										overflow: 'hidden',
										textOverflow: 'ellipsis',
										whiteSpace: 'nowrap',
									}}>
									{/* Some one liner on Global Comparatives */}
								</Typography>

							</Box>

						</Box>

						<Divider sx={{
							margin: '0px',
							flexShrink: 0,
							background: 'rgba(34, 51, 84, 0.1)',
							border: '0px',
							height: '1px',
						}} />

						<div style={{
							display: 'flex',
							justifyContent: 'stretch',
							alignItems: 'stretch',
							flexDirection: 'column',
							padding: '20px'
						}}>

							{nav[2].children.map((option, index) => (

								<MenuItem
									key={option.name}
									selected={index === selectedMenuIndex3}
									onClick={() => handleNavSelection(option)}>

									<ListItemText sx={{
										flex: '1 1 auto',
										minWidth: '0px',
										margin: '0px'
									}}>
										{option.name}
									</ListItemText>

									<ChevronRightTwoToneIcon />

								</MenuItem>

							))}

						</div>

					</Menu>
        </React.Fragment>
    )
};

export default ContentComponent;