const boxSX = {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '27px',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '& .css-1aquho2-MuiTabs-indicator': {
        position: 'absolute',
        bottom: '0px',
        width: '100%',
        transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        backgroundColor: 'rgb(36, 66, 175)',
        height: '38px',
        minHeight: '38px',
        borderRadius: '6px',
        border: '1px solid rgb(28, 52, 140)',
        boxShadow: 'rgb(101 122 199) 0px 2px 10px',
    },
    '& .Mui-selected': {
        color: '#FFF !important',
        zIndex: 5
    },
    '& .MuiButtonBase-root': {
        minWidth: 'auto',
        paddingLeft: '20px',
        paddingRight: '20px',
        marginRight: '4px',
    }
};

const tabListSX = {
    display: 'flex',
    height: '40px',
    minHeight: '40px',
    overflow: 'visible'
};

const tabSX = {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
    tapHighlightColor: 'transparent',
    backgroundColor: 'transparent',
    outline: '0px',
    border: '0px',
    margin: '0px',
    cursor: 'pointer',
    userSelect: 'none',
    verticalAlign: 'middle',
    appearance: 'none',
    textDecoration: 'none',
    fontWeight: '600',
    fontFamily: 'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
    fontSize: '0.875rem',
    lineHeight: '1.25',
    maxWidth: '360px',
    minWidth: '90px',
    position: 'relative',
    flexShrink: '0',
    overflow: 'hidden',
    whiteSpace: 'normal',
    textAlign: 'center',
    flexDirection: 'column',
    color: 'rgba(34, 51, 84, 0.7)',
    padding: '0px',
    height: '38px',
    minHeight: '38px',
    borderRadius: '6px',
    transition: 'color 0.2s ease 0s',
    textTransform: 'capitalize',
};

export {
    boxSX,
    tabListSX,
    tabSX,
}