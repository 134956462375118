
// MISC
export const ROOT = '/';
export const SIGN_IN = '/sign-in';
export const SIGN_UP = '/sign-up';
export const FORGOT_PASSWORD = '/forgot-password';

// ADMIN
export const ADMIN_DASHBOARD = '/dashboard';
export const ADMIN_USERS = '/users';
export const ADMIN_CLIENTS = '/client';
export const ADMIN_CLIENTSV2 = '/clientV2';
export const ADMIN_ROLES = '/role';
export const ADMIN_PERMISSION = '/permission';
export const DATA_SOURCE = '/data-sources';
export const DATA_SOURCE_SHOW = '/data-sources/show';
export const ANALYTIC_CONSOLES = '/analytics-console';
export const ANALYTIC_CONSOLES_SHOW = '/analytics-console/show';
export const REPORT_PREVIEW = '/report/:id';
