
import React from 'react';
import {
    Box,
	FormControl
} from '@mui/material';

import FormControlSelectComponent from './form-control-select-component';
import FormControlTextComponent from './form-control-text-component';
import MultiSelectField from './form-control-autocomplete-component';
import FormControlFileComponent from './form-control-file-component';

const FormControlComponent = (props) => {
    const {
        data:{
            type,
            sx = {}
        }
    } = props;
 
    return (
        <React.Fragment>
            <Box sx={sx}>
                <FormControl
                    sx={{
                        width: '100%',
                    }}>

                    {
                        type === 'selectbox' && <FormControlSelectComponent data={props.data}/> ||
                        type === 'multiselect' && <MultiSelectField data={props.data}/> ||
                        type === 'file' && <FormControlFileComponent data={props.data}/> ||
                        <FormControlTextComponent data={props.data}/>}
                </FormControl>
            </Box>
        </React.Fragment>
    )
};

export default FormControlComponent;