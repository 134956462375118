
import React from 'react';

import uuid from 'uuid';

import {
	TableBody
} from '@mui/material';

const TableBodyComponent = (props) => {
    const {
        children
    } = props;
    return (<React.Fragment>
                <TableBody key={`${uuid()}`}>
                    {children}
                </TableBody>
    </React.Fragment>)
}

export default TableBodyComponent;