
import React from 'react';
import {
    DialogContent,
	DialogActions,
    Grid,
} from '@mui/material';

import ButtonComponent from '../button';
import FormControlComponent from '../../form-control-component/form-control-component';

import {
    dialogActionSx,
    dialogContentSx,
    dialogContentGridFirstSx,
    dialogContentGridSecondSx,
} from './style-constants';

const DialogComponent = (props) => {
    const {
        data:{
            actions,
            formControls,
            defaultValue,
            formData, 
		    setFormData,
            type,
            hasValidated,
            setHasErrors,
            hasErrors,
        },
        children,
    } = props;

    return (
        formControls &&
        <React.Fragment>
            <DialogContent key={type} sx={dialogContentSx}>             
                <Grid container spacing={4} sx={dialogContentGridFirstSx} key='DialogFirstGrid'>
                    { !children && formControls.map(f => 
                    <Grid item xs={12} md={6} sx={dialogContentGridSecondSx} key={`DialogSecondGrid${f.name || f.label}`}>
                        <FormControlComponent data={{...f, defaultValue, formData, setFormData, hasValidated, setHasErrors, hasErrors}}/> 
                    </Grid>                    
                    || children)
                }
                </Grid>
            </DialogContent>

            { actions && <DialogActions sx={dialogActionSx}>
                    {actions && actions.map(g=> <ButtonComponent data={g}/>)}
                </DialogActions>
            }
        </React.Fragment>
    )
};

export default DialogComponent;