
import React from 'react';

import {
	Box,
	Paper,
	Typography
} from '@mui/material';

import QuickMenu from './quick-menu';

const Footer = props => {

	const {
		actions
	} = props;

	return (

		<Paper
			elevation={1}
			sx={{
				backgroundColor: 'rgb(255, 255, 255)',
				color: 'rgb(34, 51, 84)',
				transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
				padding: '0px',
				boxShadow: 'rgb(159 162 191 / 18%) 0px 9px 16px, rgb(159 162 191 / 32%) 0px 2px 2px',
				overflow: 'hidden',
				borderRadius: '0px',
				marginTop: '36px'
			}}>

			<Box
				sx={{
					position: 'fixed',
					width: '100%',
					bottom: 0,
					padding: '36px',
					display: {'xs': 'block', md: 'flex'},
					textAlign: {'xs': 'center', md: 'left'},
					alignItems: 'center',
					justifyContent: 'space-between',
				}}>

				<Typography
					variant='subtitle1'
					sx={{
						margin: '0px',
						fontSize: '14px',
						color: 'rgba(34, 51, 84, 0.7)',
						fontFamily: 'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
						fontWeight: '400',
						lineHeight: '1.75'
					}}>
					&#169; {new Date().getFullYear()} - Graphene Economics.
				</Typography>

			</Box>

			{actions && 

				<QuickMenu actions={actions} />
				
			}

		</Paper>

	);

}

export default Footer;
