import axios from 'axios';
import { ANALYTICS_CONSOLE, AUTH, CLIENTS, DATA_SOURCES, PERMISSION, ROLES } from '../config/api';
export const genAxios = (method, url, headers = undefined, data = undefined, responseType = undefined, timeout = 30000) => {
    return axios({
        method: method,
        url: url,
        headers,
        data,
        responseType,
        timeout
    })
}

export const getAnalyticsConsole =  async (storedUser) => {
    return genAxios('GET', `${ANALYTICS_CONSOLE}/`, {
        'Authorization': `Bearer ${storedUser}`
      }
  );
}


export const getAnalyticsConsoleById =  async (storedUser, consoleId) => {
  return genAxios('GET', `${ANALYTICS_CONSOLE}/${consoleId}`, {
      'Authorization': `Bearer ${storedUser}`
    }
);
}

export const getAnalyticsConsolesByCompanyId =  async (storedUser, companyId) => {
  return genAxios('GET', `${ANALYTICS_CONSOLE}/company/${companyId}`, {
      'Authorization': `Bearer ${storedUser}`
    }
);
}

export const getDataSources =  async (storedUser) => {
    return genAxios('GET', `${DATA_SOURCES}`, {
        'Authorization': `Bearer ${storedUser}`
      }
  );
}

export const getDataSourcesById =  async (storedUser, datasourceId) => {
  return genAxios('GET', `${DATA_SOURCES}/${datasourceId}`, {
      'Authorization': `Bearer ${storedUser}`
    }
  );
}

export const getDataSourcesByCompanyId =  async (storedUser, company) => {
  return genAxios('GET', `${DATA_SOURCES}/company/${company}`, {
      'Authorization': `Bearer ${storedUser}`
    }
  );
}

export const getDataSourcesYearsByCompanyId =  async (storedUser, datasource = '1') => {
  return genAxios('GET', `${DATA_SOURCES}/getAvailbleYearsByCompany/${datasource}`, {
      'Authorization': `Bearer ${storedUser}`
    }
  );
}

export const getDataSourcesJson =  async (storedUser, datasourceId) => {
  return genAxios('GET', `${DATA_SOURCES}/json/${datasourceId}`, {
      'Authorization': `Bearer ${storedUser}`
    }
  );
}

export const getValidationMessage =  async (storedUser, datasourceId) => {
  return genAxios('GET', `${DATA_SOURCES}/getValidationData/${datasourceId}`, {
      'Authorization': `Bearer ${storedUser}`
    }, undefined, 'blob', 60000
  );
}

export const getDataSourcesXML =  async (storedUser, datasourceId) => {
  return genAxios('GET', `${DATA_SOURCES}/getXMLSourceDataDetailsById/${datasourceId}`, {
      'Authorization': `Bearer ${storedUser}`
    },
    undefined, 
    'blob'
  );
}

export const getClients =  async (storedUser) => {
    return genAxios('GET', `${CLIENTS}/`, {
        'Authorization': `Bearer ${storedUser}`
      }
  );
}

export const getEmbedConfig =  async (storedUser, reportId = '18f069df-56bb-4b8c-b08b-371a7e3924c1') => {
  return genAxios('GET', `${ANALYTICS_CONSOLE}/getEmbedConfig/${reportId}`, {
      'Authorization': `Bearer ${storedUser}`
    }
  );
}

export const getPermissions =  async (storedUser) => {
    return genAxios('GET', `${PERMISSION}/`, {
        'Authorization': `Bearer ${storedUser}`
      }
  );
}

export const getReportList =  async (storedUser) => {
  return genAxios('GET', `${ANALYTICS_CONSOLE}/getReportList`, {
      'Authorization': `Bearer ${storedUser}`
    }
  );
}

export const getRoles =  async (storedUser) => {
    return genAxios('GET', `${ROLES}/`, {
        'Authorization': `Bearer ${storedUser}`
      }
  );
}

export const getSourceDataDetailsById =  async (storedUser, dataSourceId) => {
  return genAxios('GET', `${DATA_SOURCES}/getSourceDataDetailsById/${dataSourceId}`, {
      'Authorization': `Bearer ${storedUser}`
    }
  );
}

export const getAnalyticConsoleDataDetailsByCompany =  async (storedUser, companyId = '1') => {
  return genAxios('GET', `${ANALYTICS_CONSOLE}/company/${companyId}`, {
      'Authorization': `Bearer ${storedUser}`
    }
  );
}

export const getUsers = async (storedUser) => {
    return genAxios('GET', `${AUTH}/users`, {
          'Authorization': `Bearer ${storedUser}`
        }
    );
}