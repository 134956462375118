const dialogContentSx = {
    // borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    // borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    // padding: '1.5rem 2rem',
    // display: 'flex',
    // flexDirection: 'row',
    gap: '2rem',
    flex: '1 1 auto',
    overflowY: 'auto',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
}

const dialogContentGridFirstSx = {
    boxSizing: 'border-box',
    display: 'flex',
    flexFlow: 'row wrap',
    marginTop: '-36px',
    width: 'calc(100% + 36px)',
    marginLeft: '-36px',
    '& .MuiInputLabel-root': {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '1.4375em',
        color: 'rgb(34, 51, 84)',
        boxSizing: 'border-box',
        cursor: 'text',
        display: 'inline-flex',
        alignItems: 'center',
        width: '100%',
        borderRadius: '10px'
    }
}

const dialogContentGridSecondSx = {
    boxSizing: 'border-box',
    margin: '0px',
    flexDirection: 'row',
    flexBasis: { xs: '100%', md: '50%' },
    flexGrow: 0,
    maxWidth: { xs: '100%', md: '50%' }
}

const dialogActionSx = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flex: '0 0 auto',
    padding: '27px',
}

const cancelSX = {
    color: 'rgb(110, 117, 159)',
    fontWeight: 'bold',
    textTransform: 'none',
    padding: '9px 16px',
    fontSize: '0.875rem',
    lineHeight: '1.75',
    minWidth: '64px'
};

const acceptSX = {
    color: 'rgb(255, 255, 255)',
    backgroundColor: 'rgb(36, 66, 175)',
    boxShadow: 'none',
    fontWeight: 'bold',
    textTransform: 'none',
    padding: '8px 20px',
    '&:hover': {
        color: 'rgb(255, 255, 255)',
        backgroundColor: 'rgb(36, 66, 175)',
    }
};

export {
    acceptSX,
    cancelSX,
    dialogActionSx,
    dialogContentSx,
    dialogContentGridFirstSx,
    dialogContentGridSecondSx,
};