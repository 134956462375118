import React, {
	useState,
	useEffect
} from 'react';

import {
	useNavigate
} from 'react-router-dom';

import {
	TableCell,
	TableRow,
} from '@mui/material';

import { PERMISSION } from '../config/api';
import TableContent from '../components/common/pages/table-content-components';

const screen = {
	type: 'Permissions',
	heading: {
		title: 'Permission Management',
		description: 'All aspects related to permission can be managed from this page.'
	},
	search: {
		type:'text',
		placeholder:'Search by permission.',
		fullWidth:true,
		size:'small'
	},
	tableHead: [
		'Name',
		'Description',
		'Action',
		'Area'
	]
}

const getPermissions = () => fetch(`${PERMISSION}/`);

const Permission = () => {
    const [objects, setObjects] = useState([]);
	const navigate = useNavigate();

	const loadData = async () => {
		getPermissions()
		.then(response => response.json())
		.then(data => {
			setObjects(data);
		});
	}

	useEffect(() => {
		loadData();
	}, [navigate]);

	const filterOptions = (obj, _, searchValue) => {
		return obj.name.toLowerCase().includes(searchValue.toLowerCase()) ||
		obj.description.toLowerCase().includes(searchValue.toLowerCase())
	}

	const tableBodyMapper = (f) => (
		<TableRow key={`${f.id}_row`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
			<TableCell key={`${f.id}_name`}>{f.name}</TableCell>
			<TableCell key={`${f.id}_description`}>{f.description}</TableCell>
			<TableCell key={`${f.id}_action`}>{f.action}</TableCell>
			<TableCell key={`${f.id}_area`}>{f.area}</TableCell>
		</TableRow>
	);

	return (
		<React.Fragment>
			<TableContent data={{
				objects, 
				body: tableBodyMapper,
				filterOptions,
				screen
			}}/>
		</React.Fragment>
	);
}

export default Permission;