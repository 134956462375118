
import React from 'react';
import {
    Box
} from '@mui/material';
import HeaderComponent from './header-component/header-component';
const ContentComponent = (props) => {
    const {
        data:{
            title,
            description,
            component
        },
        children
    } = props;
 
    return (
        <React.Fragment>
            <Box className='container' sx={{'justifyContent': 'space-between'}}>
                <HeaderComponent data={{
                    title: title,
                    description: description
                }}>
                    {component}
                </HeaderComponent>
            </Box>
            <Box className='content-data'>
                {children}
            </Box>
        </React.Fragment>
    )
};

export default ContentComponent;