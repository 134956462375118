
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import * as axios from 'axios';

import {
	Avatar,
	Button,
	TextField,
	Paper,
	Box,
	Grid,
	Typography,
	createTheme,
	ThemeProvider,
	Container,
	Stepper,
	StepLabel,
	Step,
	FormControl,
} from '@mui/material';

import DoneTwoToneIcon from '@mui/icons-material/DoneTwoTone';

import { AUTH } from '../config/api';
import * as ROUTES from '../config/routes';

const steps = [
	'Personal Information',
	'Company Details',
	'Complete Registration'
];

const ForgotPassword = props => {

	const navigate = useNavigate();
	const mdTheme = createTheme();

	const [status, setStatus] = useState(0);
	const [activeStep, setActiveStep] = useState(0);
	const [isValid, setIsValid] = useState(false);
	const [userData, setUserData] = useState({
		email: '',
	});

	/**
	 * Collects form data by updating the state with the provided key-value pair.
	 * Sets the specified key in the userData state with the provided value.
	 * @param {string} key - The key to update in the userData state.
	 * @param {string} val - The value to assign to the specified key.
	 */
	const collectFormData = (key, val) => {
		setUserData({
			...userData,
			[key]: val
		});
	}

	/**
	 * Handles the form submission.
	 * Makes a POST request to the server with the userData.
	 * If the response contains an 'id' property, navigates to the sign-in page.
	 */
	const handleSubmit = () => {
		axios({
			data: userData,
			method: 'POST',
			url: `${AUTH}/forgot-password`,
		})
		.then(response => {
			if (response.data.id) {
				navigate(ROUTES.SIGN_IN);
			}
		});
	}


	return (

		<ThemeProvider theme={mdTheme}>

			<Box
				sx={{
					height: '100vh',
					display: 'flex',
					flex: '1 1 0%',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
					backgroundColor: 'rgb(248, 248, 248)'
				}}>

				<Container
					maxWidth='sm'
					sx={{
						width: '100%',
						margin: 'auto',
						boxSizing: 'border-box',
						display: 'block',
						paddingLeft: { sm: '18px', md: '27px' },
						paddingRight: { sm: '18px', md: '27px' },
						maxWidth: { md: '600px' }
					}}>

					<Box sx={{ textAlign: 'center' }}>

						<img
							alt=''
							src='/img/transparent-navy.png'
							style={{
								width: '30%'
							}} />

					</Box>

					<Paper
						sx={{
							backgroundColor: 'rgb(255, 255, 255)',
							color: 'rgb(34, 51, 84)',
							transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
							borderRadius: '10px',
							boxShadow: 'rgb(159 162 191 / 18%) 0px 9px 16px, rgb(159 162 191 / 32%) 0px 2px 2px',
							overflow: 'hidden',
							marginTop: '27px',
							padding: '36px',
						}}>

						<Box>

							<Typography
								component='h2'
								variant='h2'
								sx={{
									margin: '0px 0px 9px',
									fontWeight: 700,
									fontSize: '30px',
									fontFamily: 'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
									lineHeight: 1.2
								}}>
								Recover Password
							</Typography>

							<Typography
								sx={{
									margin: '0px 0px 27px',
									fontSize: '16px',
									fontFamily: 'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
									lineHeight: 1.235,
									color: 'rgba(34, 51, 84, 0.7)',
									fontWeight: 'normal',
								}}>
								Enter the email address associated to your account
							</Typography>

							<form noValidate>

								<FormControl
									sx={{
										display: 'inline-flex',
										flexDirection: 'column',
										position: 'relative',
										minWidth: '0px',
										padding: '0px',
										margin: '16px 0px 8px',
										border: '0px',
										verticalAlign: 'top',
										width: '100%',
									}}>

									<TextField
										type='email'
										label="Email Address"
										onChange={(e) => collectFormData('email', e.target.value)}
										fullWidth
										required={true}
										placeholder='Email Address'
										variant="outlined" />

								</FormControl>

							</form>

							<Button
								size='large'
								fullWidth={true}
								variant='contained'
								onClick={handleSubmit}
								sx={{
									display: 'inline-flex',
									alignItems: 'center',
									justifyContent: 'center',
									position: 'relative',
									boxSizing: 'border-box',
									outline: '0px',
									border: '0px',
									margin: '27px 0px 0px',
									cursor: 'pointer',
									userSelect: 'none',
									verticalAlign: 'middle',
									appearance: 'none',
									textDecoration: 'none',
									fontSize: '0.9375rem',
									lineHeight: '1.75',
									minWidth: '64px',
									borderRadius: '10px',
									transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
									color: 'rgb(255, 255, 255)',
									backgroundColor: 'rgb(36, 66, 175)',
									boxShadow: 'none',
									width: '100%',
									fontWeight: 'bold',
									textTransform: 'none',
									padding: '11px 24px',
								}}>
								Recover
							</Button>

						</Box>

					</Paper>

					<Box
						sx={{
							marginTop: '27px',
							textAlign: 'right',
						}}>

						<Typography
							component='a'
							href='/sign-in'
							sx={{
								margin: '0px',
								fontSize: '15px',
								fontFamily: 'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
								lineHeight: 1.57,
								color: 'rgb(34, 51, 84)',
								fontWeight: 700,
							}}>

							<strong>Try sign in again?</strong>

						</Typography>

					</Box>

				</Container>

			</Box>

		</ThemeProvider >

	);

}

export default ForgotPassword;
