
import React, {
	useState,
	useEffect
} from 'react';

import {
	useNavigate
} from 'react-router-dom'

import { Routes, Route } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import * as ROUTES from '../config/routes';

import PrivateRoute from '../components/private-route';
import PublicRoute from '../components/public-route';
import DataSource from '../pages/data-sources';
import DataSourceShow from '../pages/data-sources/show';
import Dashboard from '../pages/dashboard';
import Loading from '../pages/loading';
import SignUp from '../pages/sign-up';
import SignIn from '../pages/sign-in';
import NotFound from '../pages/404';
import Users from '../pages/users';
import Clients from '../pages/clients';
import ClientsV2 from '../pages/clientsV2';
import Roles from '../pages/roles';
import Permission from '../pages/permission';
import AnalyticConsoles from '../pages/analytic-consoles';
import ForgotPassword from '../pages/forgot-password';
import ReportPreview from '../pages/report-preview';
import Layout from '../components/layout';
// import { env } from '../env'

// const domainUrl = env.REACT_APP_AUTH0_DOMAIN;

// const clientId = env.REACT_APP_AUTH0_CLIENT_ID;

import { AppPermissionsContext } from '../components/common/app-context/app-permission-context';
import { ProtectedRoute } from '../components/common/app-context/app-route-protection-context';
import { ROLEPERMISSION } from '../config/api';
import * as axios from 'axios';
import { jwtDecode } from "jwt-decode";
import AnalyticConsoleShow from '../pages/analytic-consoles/analytic-console-show';

const GEApp = () => {
	const [permissions, setPermission] = useState();
	const [storedUser, setStoredUser] = useState();
	const [decoded, setDecoded] = useState();
	const [overview, setOverview] = useState(true);

	const navigate = useNavigate();

	useEffect(() => {
		const storage = window.localStorage.getItem('user');
		if(storage){
			setStoredUser(storage);
			const decoded = jwtDecode(storage);
			setDecoded(decoded);
			axios({
				method: 'GET',
				url: `${ROLEPERMISSION}`,
				headers:{
					'Authorization': `Bearer ${storage}`
				}
			}).then(response => {
				setPermission(response.data);
			})
		}
	}, navigate);

	const s = () => {
		return (<React.Fragment><Route path={ROUTES.ADMIN_DASHBOARD} element={ <Layout /> }>
				<Route path="" element={ <Dashboard /> } />
			</Route>
			
			<Route path={`${ROUTES.DATA_SOURCE}`} element={ <Layout /> }>
				<Route path="" element={ <ProtectedRoute data={{ area: 'Data Sources', action: 'read'}}><DataSource /></ProtectedRoute> } />
			</Route>

			<Route path={`${ROUTES.DATA_SOURCE_SHOW}/:id`} element={ <Layout /> }>
				<Route path="" element={ <ProtectedRoute data={{ area: 'Data Sources', action: 'read'}}><DataSourceShow /></ProtectedRoute> } />
			</Route>

			<Route path={`${ROUTES.ANALYTIC_CONSOLES}`} element={ <Layout /> }>
				<Route path="" element={ <ProtectedRoute data={{ area: 'Analytics Console', action: 'read'}}><AnalyticConsoles /></ProtectedRoute> } />
			</Route>

			<Route path={`${ROUTES.ANALYTIC_CONSOLES_SHOW}/:id`} element={ <Layout /> }>
				<Route path="" element={ <ProtectedRoute data={{ area: 'Analytics Console', action: 'read'}}><AnalyticConsoleShow /></ProtectedRoute> } />
			</Route>

			<Route path={ROUTES.ADMIN_USERS} element={ <Layout /> }>
				<Route path="" element={ <ProtectedRoute data={{ area: 'Users', action: 'read'}}><Users /></ProtectedRoute> } />
			</Route>

			<Route path={ROUTES.ADMIN_CLIENTS} element={ <Layout /> }>
				<Route path="" element={ <ProtectedRoute data={{ area: 'Clients', action: 'read'}}><Clients /></ProtectedRoute> } />
			</Route>
			
			<Route path={ROUTES.ADMIN_CLIENTSV2} element={ <Layout /> }>
				<Route path="" element={ <ProtectedRoute data={{ area: 'Clients', action: 'read'}}><ClientsV2 /></ProtectedRoute> } />
			</Route>

			<Route path={ROUTES.ADMIN_ROLES} element={ <Layout /> }>
				<Route path="" element={ <ProtectedRoute data={{ area: 'Roles', action: 'read'}}><Roles /></ProtectedRoute> } />
			</Route>

			<Route path={ROUTES.ADMIN_PERMISSION} element={ <Layout /> }>
				<Route path="" element={ <ProtectedRoute data={{ area: 'Permissions', action: 'read'}}><Permission /></ProtectedRoute> } />
			</Route>

			<Route path={ROUTES.REPORT_PREVIEW} element={ <Layout /> }>
				<Route path="" element={ <ProtectedRoute data={{ area: 'Report', action: 'read'}}><ReportPreview /></ProtectedRoute> } />
			</Route>
			</React.Fragment> )
	}

	return (<AppPermissionsContext.Provider value={{
			permissions,
			setPermission,
			storedUser, 
			setStoredUser,
			decoded, 
			setDecoded,
			overview,
			setOverview
		}}>
			<Auth0Provider 
				domain="dev-ssxfjmje.us.auth0.com"
				clientId="akEYoaOQG6WqNtOlJJ8vUFwGrtWXSUM4"
				redirectUri={window.location.origin}>

					<Routes>

						<Route path={ROUTES.ROOT} element={ <PublicRoute /> }>
							<Route path="" element={ <SignIn /> } />
						</Route>

						<Route path={ROUTES.SIGN_IN} element={ <PublicRoute /> }>
							<Route path="" element={ <SignIn /> } />
						</Route>

						<Route path={ROUTES.SIGN_UP} element={ <PublicRoute /> }> 
							<Route path="" element={ <SignUp /> } />
						</Route>

						<Route path={ROUTES.FORGOT_PASSWORD} element={ <PublicRoute /> }>
							<Route path="" element={ <ForgotPassword /> } />
						</Route>

						{storedUser && decoded && s()}
						{!storedUser && !decoded && <Route path="*" element={ <PublicRoute /> }>
							<Route path="*" element={ <Loading /> } />
						</Route> }

						<Route path="*" element={ <PublicRoute /> }>
							<Route path="*" element={ <NotFound /> } />
						</Route>

					</Routes>

			</Auth0Provider>	
		</AppPermissionsContext.Provider>
	);

};

export default GEApp;
