import React, {
	useState,
	useEffect,
	useContext
} from 'react';

import {
	Routes,
	useNavigate
} from 'react-router-dom';

import {
	Box,
	Button,
	IconButton,
	Typography,
	TableCell,
	TableRow,

} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DownloadTwoToneIcon from '@mui/icons-material/DownloadTwoTone';
import AutoModeIcon from '@mui/icons-material/AutoMode';

import { BASEPATH, DATA_SOURCES } from '../config/api';
import Footer from '../components/footer';
import ModalComponent from '../components/common/global-component/modal/modal-component';
import DialogComponent from '../components/common/global-component/dialog/dialog-component';
import ModalService from '../services/modal-service';
import { AppPermissionsContext } from '../components/common/app-context/app-permission-context';
import { getDataSources, getClients, getDataSourcesXML, getValidationMessage } from '../services/api-services';
import TableContent from '../components/common/pages/table-content-components';
import * as ROUTES from '../config/routes';
import axios from 'axios';

const screen = {
	type: 'Data Sources',
	heading: {
		title: 'Data Sources',
		description: 'Information below represents the CbCR data'
	},
	search: {
		type:'text',
		placeholder:'Search by client.',
		fullWidth:true,
		size:'small'
	},
	tableHead: [
		'Company',
		'Period',
		'File',
		'Id',
		'XML',
		''
	]
}

const DataSource = () => {
	const { storedUser, permissions } = useContext(AppPermissionsContext);
	const [reportList, setReportList] = useState([]);
	const [objects, setObjects] = useState([]);
	const [clients, setClients] = useState([]);
	const [customHtml, setCustomHtml] = useState();

	const navigate = useNavigate();

	const formControls = [
		{
			label: "Client Name",
			placeholder: "Your client name, please",
			helperText: "Client name is required",
			required: true,
			validation: (obj) => obj
		},
		{
			label: "Company",
			placeholder: "Select your company, please",
			name: "companyId",
			type: 'selectbox',
			options: clients,
			required: true
		},
		{
			label: "Period",
			placeholder: "Select your period, please",
			name: "year",
			helperText: "Period is required",
			required: true,
			validation: (obj) => obj
		},
		{
			placeholder: "Select your data file",
			type: 'file',
			options: objects,
			name: "file"
		}
	]

	const downloadFormControls = [
		{
			label: "Surname",
			placeholder: "Enter your surname",
			name: "surname",
			required: true,
			validation: (obj) => obj
		},
		{
			label: "First Names",
			placeholder: "Enter your first names",
			name: "firstNames",
			required: true
		},
		{
			label: "Business Telephone Number 1",
			placeholder: "Enter your business telephone number 1",
			name: "busTelNo1",
			required: true
		},
		{
			label: "Business Telephone Number 2",
			placeholder: "Enter your business telephone number 2",
			name: "busTelNo2"
		},
		{
			label: "Cellphone Number", 
			placeholder: "Enter your cellphone number",
			name: "cellNo",
			required: true
		},
		{
			label: "Email Address",
			placeholder: "Enter your email address",
			name: "emailAddress",
			type: "email",
			required: true
		}
	]

	const createModal = {
		form: {
			formControls,
			processText: 'Add data source',
			title: 'Create New Data Source',
			validation: () => true,
			type: 'create',
		},
		request: {
			method: 'POST',
			requestValidation: () => true,
			url: () => `${DATA_SOURCES}/create`,
			requestOverideMap: (formData) => {
				const form = new FormData();
				form.append('name', formData.name ?? formData.file?.name);
				form.append('xml', formData.xml ?? false);
				form.append('year', formData.year);
				form.append('company', formData.companyid);
				form.append('file', formData.file);

				return form;
			}
		}
	}

	const editModal = {
		form: {
			description: 'Configure your console settings',
			formControls,
			processText: 'Edit existing client',
			title: 'Edit Existing Client',
			validation: () => true,
			type: 'edit'
		},
		request: {
			method: 'PATCH',
			requestValidation: () => modals.edit.modalData.selected,
			url: () => `${DATA_SOURCES}/${modals.edit.modalData.selected.id}`,
		}
	}

	const deleteModal = {
		form: {
			description: 'Are you sure, you know what you doing?',
			formControls: [
				{
					label: "Name",
					type: 'readonly',
					disabled: true,
				},
				{
					label: "ID",
					type: 'readonly',
					disabled: true,
				}
			],
			processText: 'Delete existing client',
			title: 'Delete Existing Client',
			validation: () => true,
			type: 'delete'
		},
		request: {
			method: 'DELETE',
			requestValidation: () => modals.delete.modalData.selected.id,
			url: () => `${DATA_SOURCES}/${modals.delete.modalData.selected.id}`,
		}
	};

	const downloadModal = {
		form: {
			formControls: downloadFormControls,
			processText: 'Download file',
			title: 'Download Information',
			validation: () => true,
			type: 'download',
			customHtml	
		},
		request: {
			method: 'PATCH',
			requestValidation: () => modals.download.modalData.selected.id,
			url: () => `${DATA_SOURCES}/${modals.download.modalData.selected.id}`,
			requestOverideMap: (data) => data
		},		
	};

	const modals = {
		create: ModalService(createModal),
		edit: ModalService(editModal),
		delete: ModalService(deleteModal),
		download: ModalService(downloadModal),
	}

	useEffect(() => {
		getClients(storedUser)
			.then(response => setClients(response.data));
	}, []);

	useEffect(() => {
		if(modals.download.result){
			handleDownload(modals.download.selected.id, modals.download.selected.name);
		}
	}, [modals.download.result]);

	useEffect(() => {
		getDataSources(storedUser)
			.then(response => { 
				response.data = response.data.map(f=> ({ ...f, companyName: f.client.name }));
				setObjects(response.data);
			});
	}, [clients, modals.delete.result, modals.edit.result, modals.create.result, modals.download.result]);

	useEffect(() => {
		if(modals.create && modals.create.resultData){
			generateXML(modals.create.resultData);
		}
	}, [modals.create.resultData]);

	const generateXML = async (id) =>{
		const validationMessage = await getValidationMessage(storedUser, id);
		downloadFile(validationMessage.data);
		var response = await getDataSources(storedUser);
		response.data = response.data.map(f=> ({ ...f, companyName: f.client.name }));
		setObjects(response.data);
	}

	const actions = [
		{
			icon: <AddIcon />,
			name: 'Add New Console',
			callback: modals.create.open
		}
	];

	const filterOptions = (obj, _, searchValue) => {
		return obj && (obj.name && obj.name.toLowerCase().includes(searchValue.toLowerCase()) ||
		obj.client && obj.client.name && obj.client.name.toLowerCase().includes(searchValue.toLowerCase()) ||
		obj.templateName && obj.templateName.toLowerCase().includes(searchValue.toLowerCase()) ||
		obj.year && obj.year.toLowerCase().includes(searchValue.toLowerCase()))
	}

	const handleDownload = (id, file) => {
		const fileNameWithoutExtension = file.replace('.xlsm', '')
		getDataSourcesXML(storedUser, id)
			.then(response => {
				const url = window.URL.createObjectURL(response.data);
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', `${fileNameWithoutExtension}.xml`);
				document.body.appendChild(link);
				link.click();
				URL.revokeObjectURL(url);
				document.body.removeChild(link);
			})
			.catch(error => {
				console.error('Error downloading file: ', error);
			});
	}

	const tableBodyMapper = (f) => (
		<TableRow key={`${f.id}_row`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
			<TableCell key={`${f.id}_company`}>{f.companyName}</TableCell>
			<TableCell key={`${f.id}_period`}>{f.year}</TableCell>
			<TableCell key={`${f.id}_file`}>{f.file}</TableCell>
			<TableCell key={`${f.id}_id`}>{f.id}</TableCell>
			<TableCell key={`${f.id}_xml`}>{f.xml ? 'Yes' : 'No'}</TableCell>

			<TableCell key={`${f.id}_actions`}>
				<Typography key={`${f.id}_typo`}>
					{ permissions && permissions['Data Sources'] && permissions['Data Sources'].read && <IconButton key={`${f.id}_show`}
						onClick={(e) => navigate(`${ROUTES.DATA_SOURCE_SHOW}/${f.id}`)}>
						<VisibilityIcon />
					</IconButton>
					}
					{ permissions && permissions['Data Sources'] && permissions['Data Sources'].update && false && <IconButton key={`${f.id}_edit`}
						onClick={(e) => modals.edit.open(e, f)}>
						<EditTwoToneIcon />
					</IconButton>
					}
					{ permissions && permissions['Data Sources'] && permissions['Data Sources'].delete && <IconButton key={`${f.id}_delete`}
						onClick={(e) => modals.delete.open(e, f)}>
						<DeleteTwoToneIcon />
					</IconButton>
					}
					{permissions && permissions['Data Sources'] && permissions['Data Sources'].download && !f.xml && <IconButton key={`${f.id}_download_regen`}
						onClick={(e) => generateXML(f.id)}>
						<AutoModeIcon/>
					</IconButton>
					}
					{permissions && permissions['Data Sources'] && permissions['Data Sources'].download && <IconButton key={`${f.id}_download`}
						onClick={(e) => modals.download.open(e, f)}>
						<DownloadTwoToneIcon/>
					</IconButton>
					}
				</Typography>
			</TableCell>
		</TableRow>
	);

	const downloadFile = async (e) => {
		 const url = window.URL.createObjectURL(e);
		 const a = document.createElement('a');
		 a.href = url;
		 a.download = 'file.pdf'; // specify the filename
		 a.click();
   
		 // Clean up the URL object after the download is triggered
		 window.URL.revokeObjectURL(url);
	}

	const getModalComponent = (modalData) => {
		const validation = modalData && modalData.validation && modalData.validation();
		return (validation && <ModalComponent key={modalData.type} data={modalData}>
			<Box>
				{
					((customHtml && <Button onClick={(e) => downloadFile(e)}>test</Button> ) || 
					<form noValidate autoComplete='off'>
						{
							<DialogComponent key={modalData.type} data={{...modalData, defaultValue: modalData.selected }}></DialogComponent>
						}
					</form>)
				}
			</Box>
		</ModalComponent>
	)};

	return (
		<React.Fragment>
			<TableContent data={{
				objects, 
				body: tableBodyMapper,
				filterOptions,
				screen
			}}>
				<Footer actions={ permissions.Users.create && actions} />
				{
					[
						getModalComponent(modals.create.modalData),  
						getModalComponent(modals.edit.modalData),
						getModalComponent(modals.delete.modalData),
						getModalComponent(modals.download.modalData)
					]
				}
			</TableContent>
		</React.Fragment>
	);
}

export default DataSource;