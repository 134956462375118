
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from "react-router-dom";

import './index.scss';
import GEApp from './app/app';
import store from './redux/store';
import reportWebVitals from './report-web-vitals';

ReactDOM.render(

	<React.StrictMode>

		<BrowserRouter>
				
			<GEApp />

		</BrowserRouter>

	</React.StrictMode>,

	document.getElementById('root')

);

reportWebVitals();
