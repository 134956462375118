
import React from 'react';
import { Outlet } from "react-router-dom";

const PublicRoute = () => {

	return <Outlet />

};

export default PublicRoute;
