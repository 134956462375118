
import React from 'react';

import {
	Dialog
} from '@mui/material';

const Modal = props => {

	const {
		toggle,
		state,
		children,
	} = props;

	return (

		<Dialog
			open={state}
			onClose={() => toggle(false)}
			sx={{
				height: '100%',
				outline: '0px',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				width: '100%',
				'& .MuiPaper-root': {
					width: '100%',
					maxWidth: '960px'
				},
			}}>

			{children}

		</Dialog>

	);

}

export default Modal;
