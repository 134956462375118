const getProperty = (obj, keys) => {
    return keys.reduce((f, i)=> {
        if(f[i]){
            return f[i];
        }
        return obj;
    }, obj);
}

const collectFormData = (props, key, val) => {
    const { 
        data: {
            setFormData, 
            formData 
        }
    } = props
    
    if (val !== '') {
        setFormData({
            ...formData,
            [key]: val
        });
    }
}

export {
    getProperty,
    collectFormData
};