
import React, {
	useState,
	useContext,
	useEffect
} from 'react';

import {
	useNavigate
} from 'react-router-dom';

import {
	Box,
	IconButton,
	Divider,
	Badge,
	Link,
	createTheme,
	ThemeProvider,
	Typography,
	Button,
	Menu,
	MenuItem,
	DialogContent,
	DialogActions,
	ListItemText
} from '@mui/material';

import NotificationsIcon from '@mui/icons-material/Notifications';

import KeyboardArrowDownTwoToneIcon from '@mui/icons-material/KeyboardArrowDownTwoTone';
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone';

import * as ROUTES from '../config/routes';
import UserAvatar from '../components/avatar';
import Modal from '../components/modal';
import Report from './report/report';
import { AppContext } from '../utils/app-context';
import { AppPermissionsContext } from './common/app-context/app-permission-context';
import SignUp from '../pages/sign-up';

import ContentComponent from './layout/content-component';
import LeftSideComponent from './layout/left-side-component';


const mdTheme = createTheme();

const Layout = () => {
	const { decoded } = useContext(AppPermissionsContext);
	const [secondLevelVisible, setSecondLevelVisible] = useState(false);
	const [secondLevelItems, setSecondLevelItems] = useState([]);

	const [selectedMenuIndex, setSelectedMenuIndex] = useState(0);
	const [selectedMenuIndex2, setSelectedMenuIndex2] = useState(0);
	const [selectedMenuIndex3, setSelectedMenuIndex3] = useState(0);

	const [anchorEl, setAnchorEl] = useState(null);
	const [anchorEl2, setAnchorEl2] = useState(null);
	const [anchorEl3, setAnchorEl3] = useState(null);

	const navigate = useNavigate();	

	const [modalOpen, setModalOpen] = useState(false); 
	const { permissions, setOverview } = useContext(AppPermissionsContext);

	const handleOverviewNavClick = () => {
		setOverview(true);
	};

	const handleNavClick = (e, index) => {

		setSelectedMenuIndex(index);
		setAnchorEl(e.currentTarget);

	};

	const handleNavClick2 = (e, index) => {
		setSelectedMenuIndex2(index);
		setAnchorEl2(e.currentTarget);
	};

	const handleNavClick3 = (e, index) => {
		setSelectedMenuIndex3(index);
		setAnchorEl3(e.currentTarget);
	};

	const handleNavSelection = (item) => {

		setAnchorEl(null);
		setAnchorEl2(null);
		setAnchorEl3(null);

		setOverview(false);
		setActiveReportTab(item);

		if (item.children) {
			setSecondLevelVisible(true);
			setSecondLevelItems(item.children);
		}

	};

	const setActiveReportTab = (page) => {

		window.localStorage.setItem('activeReportPage', JSON.stringify(page)); // TODO use store instead of local storage
		window.report.setPage(page.id);

	};

	return (
		<ThemeProvider theme={mdTheme}>
			
			{decoded && decoded.tandc && decoded.forgot_password &&

				<Box sx={{
					flex: '1 1 0%',
					height: '100%'
				}}>

					{/* HORIXZONTAL HEADER STRIP */}
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							height: '80px',
							color: 'rgb(110, 117, 159)',
							padding: '0px 18px',
							right: '0px',
							left: { md: '290px' },
							zIndex: 6,
							backgroundColor: 'rgba(255, 255, 255, 0.95)',
							backdropFilter: 'blur(3px)',
							position: 'fixed',
							justifyContent: 'space-between',
							width: { xs: '100%', md: 'auto' },
							boxShadow: 'rgb(34 51 84 / 20%) 0px 2px 8px -3px, rgb(34 51 84 / 10%) 0px 5px 22px -4px'
						}}>

						<div style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
						}}>

							{window.location.pathname.includes(ROUTES.ANALYTIC_CONSOLES_SHOW) &&

								<>

									{!secondLevelVisible &&

										<Box sx={{
											display: 'inline-flex'
										}}>

											<Button
												size='small'
												onClick={handleOverviewNavClick}
												sx={{
													fontSize: '0.8125rem',
													minWidth: '64px',
													borderRadius: '10px',
													transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
													fontWeight: 'bold',
													textTransform: 'none',
													lineHeight: 1.5,
													padding: '7px 18px',
													backgroundColor: 'rgba(110, 117, 159, 0.1)',
													color: 'rgb(88, 93, 127)',
												}}>
												Overview
											</Button>

											<Button
												size='small'
												onClick={handleNavClick}
												endIcon={<KeyboardArrowDownTwoToneIcon />}
												sx={{
													fontSize: '0.8125rem',
													minWidth: '64px',
													borderRadius: '10px',
													transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
													fontWeight: 'bold',
													textTransform: 'none',
													lineHeight: 1.5,
													padding: '7px 18px',
													backgroundColor: 'rgba(110, 117, 159, 0.1)',
													color: 'rgb(88, 93, 127)',
													marginLeft: '10px'
												}}>
												Business Insights
											</Button>

											<Button
												size='small'
												onClick={handleNavClick2}
												endIcon={<KeyboardArrowDownTwoToneIcon />}
												sx={{
													fontSize: '0.8125rem',
													minWidth: '64px',
													borderRadius: '10px',
													transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
													fontWeight: 'bold',
													textTransform: 'none',
													lineHeight: 1.5,
													padding: '7px 18px',
													backgroundColor: 'rgba(110, 117, 159, 0.1)',
													color: 'rgb(88, 93, 127)',
													marginLeft: '10px'
												}}>
												Tax Risk
											</Button>

											{/* <Button
												size='small'
												onClick={handleNavClick3}
												endIcon={<KeyboardArrowDownTwoToneIcon />}
												sx={{
													fontSize: '0.8125rem',
													minWidth: '64px',
													borderRadius: '10px',
													transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
													fontWeight: 'bold',
													textTransform: 'none',
													lineHeight: 1.5,
													padding: '7px 18px',
													backgroundColor: 'rgba(110, 117, 159, 0.1)',
													color: 'rgb(88, 93, 127)',
													marginLeft: '10px'
												}}>
												Global Comparatives
											</Button> */}

										</Box>

									}

									{secondLevelVisible &&

										<Box sx={{
											display: 'inline-flex'
										}}>

											<Button
												size='small'
												onClick={() => setSecondLevelVisible(false)}
												startIcon={<ArrowBackIosNewTwoToneIcon />}
												sx={{
													fontSize: '0.8125rem',
													minWidth: '64px',
													borderRadius: '10px',
													transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
													fontWeight: 'bold',
													textTransform: 'none',
													lineHeight: 1.5,
													padding: '7px 18px',
													backgroundColor: 'rgba(110, 117, 159, 0.1)',
													color: 'rgb(88, 93, 127)'
												}}>
												Go back
											</Button>

											{secondLevelItems.length && secondLevelItems.map((item, index) => (

												<Button
													size='small'
													key={item.id}
													onClick={() => handleNavSelection(item)}
													sx={{
														fontSize: '0.8125rem',
														minWidth: '64px',
														borderRadius: '10px',
														transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
														fontWeight: 'bold',
														textTransform: 'none',
														lineHeight: 1.5,
														padding: '7px 18px',
														backgroundColor: 'rgba(110, 117, 159, 0.1)',
														color: 'rgb(88, 93, 127)',
														marginLeft: '10px'
													}}>
													{item.name}
												</Button>

											))}

										</Box>

									}

								</>

							}

						</div>

						<Box>

							<Badge
								variant='dot'
								sx={{
									display: 'inline-flex',
									alignItems: 'center',
									justifyContent: 'center',
									position: 'relative',
									boxSizing: 'border-box',
									tapHighlightColor: 'transparent',
									outline: '0px',
									border: '0px',
									margin: '0px',
									cursor: 'pointer',
									userSelect: 'none',
									verticalAlign: 'middle',
									appearance: 'none',
									textDecoration: 'none',
									textAlign: 'center',
									flex: '0 0 auto',
									fontSize: '1.5rem',
									overflow: 'visible',
									padding: '8px',
									width: '36px',
									height: '36px',
									borderRadius: '12px',
									background: 'rgba(85, 105, 255, 0.1)',
									transition: 'background 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
									color: 'rgb(85, 105, 255)'
								}}>

								<NotificationsIcon size='medium' />

							</Badge>

							<IconButton
								size='medium'
								sx={{
									display: 'inline-flex',
									alignItems: 'center',
									justifyContent: 'center',
									position: 'relative',
									boxSizing: 'border-box',
									tapHighlightColor: 'transparent',
									backgroundColor: 'transparent',
									outline: '0px',
									border: '0px',
									margin: '0px 0px 0px 9px',
									cursor: 'pointer',
									userSelect: 'none',
									verticalAlign: 'middle',
									appearance: 'none',
									textDecoration: 'none',
									textAlign: 'center',
									flex: '0 0 auto',
									fontSize: '1.5rem',
									overflow: 'visible',
									transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
									color: 'rgb(85, 105, 255)',
									width: '36px',
									padding: '0px',
									height: '36px',
									borderRadius: '12px',
								}}>

								<UserAvatar sx={{
									position: 'relative',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									flexShrink: 0,
									fontFamily: 'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
									lineHeight: 1,
									overflow: 'hidden',
									userSelect: 'none',
									fontSize: '14px',
									fontWeight: 'bold',
									height: '90%',
									width: '90%',
									borderRadius: '12px',
									backgroundColor: '#011948',
									textTransform: 'capitalize'
								}}
									text={`${decoded.name[0]} ${decoded.surname[0]}`} />

							</IconButton>

						</Box>

					</Box>

					<LeftSideComponent prop={{setModalOpen, navigate}}/>

					<ContentComponent prop={{anchorEl,
        anchorEl2,
        anchorEl3,
        setAnchorEl,
        setAnchorEl2,
        setAnchorEl3,
        handleNavSelection,
        selectedMenuIndex,
        selectedMenuIndex2,
        selectedMenuIndex3}}/>

					{/* REPORT MODAL */}
					
					<Modal
						toggle={setModalOpen}
						state={modalOpen}>

						<DialogContent>

							<Report />

						</DialogContent>

						<DialogActions
							sx={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'flex-end',
								flex: '0 0 auto',
								padding: '27px',
							}}>

							<Button
								variant='text'
								onClick={() => setModalOpen(false)}
								sx={{
									color: 'rgb(110, 117, 159)',
									fontWeight: 'bold',
									textTransform: 'none',
									padding: '9px 16px',
									fontSize: '0.875rem',
									lineHeight: '1.75',
									minWidth: '64px'
								}}>
								Cancel
							</Button>

						</DialogActions>

					</Modal>
				</Box>
			}

			{decoded && !decoded.forgot_password && 
				<SignUp data={decoded}/>			
			}

		</ThemeProvider>

	);

}

export default Layout;
