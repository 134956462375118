
import React from 'react';
import {
    Button
} from '@mui/material';

const ButtonComponent = (props) => {
    const {
        data: {
            sx,
            title,
            action,
            disabled=false,
            type
        }
    } = props;
    
    return (
        <React.Fragment>
             <Button
                variant='text'
                onClick={action}
                disabled={disabled}
                sx={sx}
                key={type}>
                
                {title}
            </Button>
        </React.Fragment>
    )
};

export default ButtonComponent;