import React from "react";

const NotFound = () => {

	return (

		<div>404!</div>

	)

}

export default NotFound;
