
import React, {
    useContext
} from 'react';

import { PrimaryNavigation } from '../nav';
import logo from '../../assets/img/logo.jpg';
import UserAvatar from '../../components/avatar';
import { AppPermissionsContext } from '../../components/common/app-context/app-permission-context';

import {
	Box,
	IconButton,
	Divider,
	Link,
	Typography,
} from '@mui/material';
import EventIcon from '@mui/icons-material/Event';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import AssessmentIcon from '@mui/icons-material/Assessment';
import * as ROUTES from '../../config/routes';

const LeftSideComponent = (props) => {

    const {
        prop:{
            setModalOpen,
            navigate
        }
    } = props

    const { decoded } = useContext(AppPermissionsContext);

    const handleLogout = () => {
		window.localStorage.removeItem('user');
		navigate(ROUTES.SIGN_IN);
	}

    return (
        <React.Fragment>
           {/* LHS SIDE BAR */}
            <Box className='left-side-nav'
                sx={{
                    display: { xs: 'none', lg: 'inline-block' }
                }}>

                <div className='wrapper'>
                    <div className='content'>
                        <Box className='logo'>
                            <Link href='/dashboard'>
                            <img src={logo}
                                alt='Graphene Economics'
                                style={{
                                    width: '70%'
                                }} />

                            </Link>
                        </Box>

                        <Divider className='divider-ui' sx={{
                            flexShrink: 0,
                            border: '0px',
                            height: '1px',
                            margin: '27px 18px',
                            background: 'rgba(255, 255, 255, 0.1)',
                        }} />

                        <Box sx={{
                            textAlign: 'center',
                            marginLeft: '18px',
                            marginRight: '18px',
                            position: 'relative'
                        }}>

                            <UserAvatar sx={{
                                position: 'relative',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexShrink: 0,
                                fontFamily: 'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
                                lineHeight: 1,
                                borderRadius: '50%',
                                overflow: 'hidden',
                                userSelect: 'none',
                                fontSize: '25px',
                                fontWeight: 'bold',
                                width: '68px',
                                height: '68px',
                                marginBottom: '18px',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                backgroundColor: '#616e88',
                                textTransform: 'capitalize'
                            }}
                                text={`${decoded.name[0]} ${decoded.surname[0]}`} />

                        </Box>

                        <Box sx={{
                            textAlign: 'center',
                            marginLeft: '18px',
                            marginRight: '18px',
                            position: 'relative',
                        }}>

                            <Typography
                                variant="h4"
                                sx={{
                                    margin: '0px',
                                    fontWeight: 700,
                                    fontSize: '16px',
                                    fontFamily: 'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
                                    lineHeight: 1.235,
                                    color: 'rgb(255, 255, 255)'
                                }}>
                                {`${decoded.name} ${decoded.surname}`}
                            </Typography>

                            <Typography
                                variant="subtitle1"
                                sx={{
                                    margin: 0,
                                    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                                    fontWeight: 400,
                                    fontSize: '0.8rem',
                                    lineHeight: 1.75,
                                    letterSpacing: '0.00938em'
                                }}>
                                {decoded.email}
                            </Typography>

                        </Box>

                        <Divider sx={{
                            flexShrink: 0,
                            border: '0px',
                            height: '1px',
                            margin: '27px 18px',
                            background: 'rgba(255, 255, 255, 0.1)',
                        }} />

                        <Box>
                            <PrimaryNavigation />
                        </Box>

                    </div>

                </div>
                {/*FOOTER SIDE BAR*/}
                <Divider sx={{
                    margin: 0,
                    flexShrink: 0,
                    border: 0,
                    height: '1px',
                    background: 'rgba(255, 255, 255, 0.1)'
                }} />

                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    boxPack: 'center',
                    justifyContent: 'center',
                    height: '60px'
                }}>

                    <IconButton
                        size='medium'
                        sx={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            position: 'relative',
                            boxSizing: 'border-box',
                            tapHighlightColor: 'transparent',
                            outline: '0px',
                            border: '0px',
                            margin: '0px',
                            cursor: 'pointer',
                            userSelect: 'none',
                            verticalAlign: 'middle',
                            appearance: 'none',
                            textDecoration: 'none',
                            textAlign: 'center',
                            flex: '0 0 auto',
                            fontSize: '1.5rem',
                            overflow: 'visible',
                            borderRadius: '8px',
                            padding: '8px',
                            background: 'rgba(255, 255, 255, 0.1)',
                            color: 'rgba(255, 255, 255, 0.7)',
                            transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
                        }}>
                        <EventIcon />
                    </IconButton>

                    <IconButton
                        onClick={() => setModalOpen(true)}
                        sx={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            position: 'relative',
                            boxSizing: 'border-box',
                            tapHighlightColor: 'transparent',
                            outline: '0px',
                            border: '0px',
                            margin: '0 10px',
                            cursor: 'pointer',
                            userSelect: 'none',
                            verticalAlign: 'middle',
                            appearance: 'none',
                            textDecoration: 'none',
                            textAlign: 'center',
                            flex: '0 0 auto',
                            fontSize: '1.5rem',
                            overflow: 'visible',
                            borderRadius: '8px',
                            padding: '8px',
                            background: 'rgba(255, 255, 255, 0.1)',
                            color: 'rgba(255, 255, 255, 0.7)',
                            transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
                        }}
                        size='medium'>
                        <AssessmentIcon />
                    </IconButton>

                    <IconButton
                        onClick={handleLogout}
                        sx={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            position: 'relative',
                            boxSizing: 'border-box',
                            tapHighlightColor: 'transparent',
                            outline: '0px',
                            border: '0px',
                            margin: '0px',
                            cursor: 'pointer',
                            userSelect: 'none',
                            verticalAlign: 'middle',
                            appearance: 'none',
                            textDecoration: 'none',
                            textAlign: 'center',
                            flex: '0 0 auto',
                            fontSize: '1.5rem',
                            overflow: 'visible',
                            borderRadius: '8px',
                            padding: '8px',
                            background: 'rgba(255, 255, 255, 0.1)',
                            color: 'rgba(255, 255, 255, 0.7)',
                            transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
                        }}
                        size='medium'>
                        <PowerSettingsNewIcon />
                    </IconButton>
                </Box>
            </Box>
        </React.Fragment>
    )
};

export default LeftSideComponent;