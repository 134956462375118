import React, { useState, useEffect } from 'react';
import {
    TextField
} from '@mui/material';

import { collectFormData } from './form-control-helper-component';


const getProperty = (obj, keys) => {
    return keys.reduce((f, i)=> {
        return f[i] ?? "";
    }, obj);
}

const FormControlTextComponent = (props) => {
    const {
        data:{
            disabled = false,
            label,
            placeholder,
            defaultValue,
            type = undefined,
            name,
            helperText,
            required = false,
            validation = (_) => true,
            hasValidated = false,
            setHasErrors,
            hasErrors,
        }
    } = props;

    const fieldName = (name ?? label).toLowerCase()?.split('.');

    const [info, setInfo] = useState(true);

    const isCurrentlyErrored = hasErrors && hasErrors.length > 0 && hasErrors.find(f=> fieldName.find(g=> f == g));

    const processValidation = (event) => {
        const valid = event?.target?.value == undefined || event?.target?.value == "" ? undefined : validation(event?.target?.value);
        setInfo(valid ?? false);
        setHasErrors(oldArray => {
            return !valid ? [...oldArray, fieldName] : oldArray.filter(f=> fieldName.find(g=> f != g));
        });
    }

    useEffect(() => {
        if(defaultValue == null){
            processValidation(defaultValue && defaultValue[fieldName]);
        }
	}, []);

    return (
        <React.Fragment>
             <TextField
                    autoComplete="new-password"
                    disabled={disabled}
                    required={required}
                    label={label}
                    defaultValue={defaultValue && defaultValue && getProperty(defaultValue, fieldName)}
                    onChange={(e) => { 
                        collectFormData(props, fieldName, e.target.value); 
                        processValidation(e);
                    }}
                    fullWidth
                    placeholder={placeholder}
                    type={type}
                    variant="outlined"
                    helperText={hasValidated && isCurrentlyErrored && helperText} // && !info
                    error={ hasValidated && isCurrentlyErrored }
                    />
        </React.Fragment>
    )
};

export default FormControlTextComponent;