
import React, {
	useState,
	useEffect
} from 'react';

import {
	Box,
	Typography
} from '@mui/material';

import Modal from '../../../modal';
import { h4, divText } from '../../style-constants';
import DialogComponent from '../dialog/dialog-component';

const ModalComponent = (props) => {
    const {
        data:{
            title,
            description,
            state,
            toggle,
            defaultModalState,
        },
        children
    } = props;

    return 	<Modal
			toggle={toggle}
			state={state}>
                <Box sx={{padding: '1rem'}}>
                    <Typography
                        variant='h4'
                        component='div'
                        sx={h4}>
                        {title}
                    </Typography>

					<Typography
						variant='subtitle2'
						component='div'
						sx={divText}>
						{description}
					</Typography>
                </Box>
                {children}
			</Modal>
}

export default ModalComponent;