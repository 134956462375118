
export const nav = [

	{
		name: 'Business Insights',
		desc: '',
		children: [
			{
				name: 'Entity substance and contribution',
				id: 'ReportSection50541275beb8b0b48259',
				desc: 'Description about Entity substance here'
			},
			{
				name: 'Business scale and effeciency',
				id: 'ReportSection',
				desc: 'Description about Business scale'
			}
		]
	},
	{
		name: 'Tax Risk',
		desc: '',
		children: [
			{
				name: 'Geographic coverage',
				id: 'ReportSectionc135e077cc2ee9d0d093',
				desc: '',
				children: [
					{
						name: 'Group Footprint',
						id: 'ReportSection77be7d9c3fe5d36b08da',
						desc: 'Description about Entity substance here'
					},
					{
						name: 'Related Party Revenues',
						id: 'ReportSection01de9ad4860155ca6039',
						desc: 'Description about Business scale'
					},
					{
						name: 'Group Activities - BEPS Risk',
						id: 'ReportSection6423dcf7f4a3de68003b',
						desc: 'Description about Entity substance here'
					},
					{
						name: 'Group Activities - Less Risk',
						id: 'ReportSection84b0e5ed921a4b161fc3',
						desc: 'Description about Business scale'
					}
				]
			},
			{
				name: 'Country / entity comparison',
				id: 'ReportSection8e323322e3b45787596f',
				desc: '',
				children: [
					{
						name: 'Profitability',
						id: 'ReportSection8646e669cf974367b1be',
						desc: ''
					},
					{
						name: 'Effective Tax Rate',
						id: 'ReportSection1e8f5d7b4a506598103a',
						desc: ''
					},
					{
						name: 'Pre-tax Return',
						id: 'ReportSection6423dcf7f4a3de68003b',
						desc: ''
					},
					{
						name: 'Post-tax Return',
						id: 'ReportSection5840d62e436f1f2df8a4',
						desc: ''
					},
					{
						name: 'Significant activity - low levels',
						id: 'ReportSection6b7c4160d45d3441ea76',
						desc: ''
					}
				]
			},
			{
				name: 'Substance and profitibility',
				id: 'ReportSection1de5bf82a7ec789d012f',
				desc: '',
				children: [
					{
						name: 'Revenue per Employee',
						id: 'ReportSectionbf612583b0e6ac241b00',
						desc: ''
					},
					{
						name: 'Profitibilty per Employee',
						id: 'ReportSectiond025cb4f1402e5b6010f',
						desc: ''
					},
					{
						name: 'Significant profits - little activity',
						id: 'ReportSection2e76437d8e6b6c73fe82',
						desc: ''
					},
					{
						name: 'Revenue per tangible asset',
						id: 'ReportSection6816c12bac348c66e0c5',
						desc: ''
					},
					{
						name: 'Profitibility per tangible asset',
						id: 'ReportSection3dd64d65fa43f6109623',
						desc: ''
					},
					{
						name: 'Significant activity - low levels',
						id: 'ReportSection8c4ae5b121a3c7fe6920',
						desc: ''
					}
				]
			},
			{
				name: 'Structure / Activity',
				id: 'ReportSection317ecf875f64cbd27bff',
				desc: '',
				children: [
					{
						name: 'Mobile Activities',
						id: 'ReportSectiona05bf594ad68449c84bc',
						desc: ''
					},
					{
						name: 'Group Structure Changes',
						id: 'ReportSection78dc66584af051a02ea2',
						desc: ''
					},
					{
						name: 'IP Separated',
						id: 'ReportSectionde63b3b84d40d559710c',
						desc: ''
					},
					{
						name: 'Group Marketing Entities',
						id: 'ReportSectionc1d7f57a2b314f4e7daa',
						desc: ''
					},
					{
						name: 'Procurement Entities',
						id: 'ReportSection33edb01198f0e76fa9e8',
						desc: ''
					}
				]
			},
			{
				name: 'Tax registration / payment',
				id: 'ReportSection317ecf875f64cbd27bff',
				desc: '',
				children: [
					{
						name: 'Significant profits - low levels',
						id: 'ReportSectionab9b9c7428045b16a870',
						desc: ''
					},
					{
						name: 'Income Tax Paid',
						id: 'ReportSectionb5c537e4bc037dcfb134',
						desc: ''
					},
					{
						name: 'Dual Resident Entities',
						id: 'ReportSection168a19e555f7e17a064f',
						desc: ''
					},
				]
			},
			{
				name: 'Context',
				id: 'ReportSection67a9719e21b1196ad5de',
				desc: '',
				children: [
					{
						name: 'Market Trends',
						id: 'ReportSection4e9ef7e7a705603ffe7f',
						desc: ''
					},
					{
						name: 'Constituent Entity',
						id: 'ReportSection2a7546bbf8341aa202ed',
						desc: ''
					}
				]
			}
		]
	},
	{
		name: 'Global Comparitives',
		desc: '',
		children: [
			{
				name: 'SA Headquatered MNEs',
				id: 'ReportSection6fee50d51664150a3cc9',
				children: [
					{
						name: 'Profitibility',
						id: 'ReportSection7c72875063dbfc1bb76a'
					},
					{
						name: 'Effective Tax Rate',
						id: 'ReportSection035aad0dd7cb2434f590'
					}
				]
			}
		]
	}

]
