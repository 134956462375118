
import React, {
	useState,
	useContext
} from 'react';

import {
	Box,
} from '@mui/material';

import { acceptSX, cancelSX } from '../components/common/global-component/dialog/style-constants';
import { genAxios } from '../services/api-services';
import { AppPermissionsContext } from '../components/common/app-context/app-permission-context';
import { Backdrop } from '@mui/material';
import ModalComponent from '../components/common/global-component/modal/modal-component';
import DialogComponent from '../components/common/global-component/dialog/dialog-component';

const omit = (obj, props) => {
	const result = { ...obj };
	props.forEach(function(prop) {
	  delete result[prop];
	});
	return result;
  }

const dialogActionSx = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flex: '0 0 auto',
    padding: '27px',
}

const buttonMap = (action, sx, title, disabled, type) => ({
    action, 
    sx,
    title,
	disabled,
	type
});

const ModalService = (props) => {
	const { 
		form: {
			closeText = 'Cancel',
			description,
			formControls,
			processText,
			disabled = false,
			title,
			validation,
			type,
			customHtml,
		},
		request: {
			dataParam,
			method,
			requestValidation,
			url,
			requestOverideMap
		}
	} = props ?? {};

	
	const { storedUser } = useContext(AppPermissionsContext);
	const [formData, setFormData] = useState({});
	const [oldFormData, setOldFormData] = useState({});
	const [modalOpen, setModalOpen] = useState(false);
	const [result, setResult] = useState(false);
	const [resultData, setResultData] = useState(false);
	const [selected, setSelected] = useState(null);
	const [hasErrors, setHasErrors] = useState([]);
	const [hasValidated, setHasValidated] = useState(null);
     
	const open = (a, data) => {
		setModalOpen(true);
		setHasErrors([]);
		if(data){
			setSelected(data);
			setFormData(data);
			setOldFormData(data);
		}
	};

	const close = () => {
		setFormData({});
		setResult(false);
		setSelected(null);
		setModalOpen(false);
		setHasValidated(false);
	};

	const request = async () => {
		var omitData = formData;
		if(method === 'PATCH'){
			const omitKeys = Object.keys(oldFormData).filter( key => oldFormData[key] === formData[key] );
			omitData = omit(formData, omitKeys)
		}

		setHasValidated(true);
		var data = dataParam ? {
			...omitData,
			...dataParam
		} : { ...omitData };

		var hasOverride = false;

		if(requestOverideMap){
			data = requestOverideMap(omitData);
			hasOverride = true;
		}

		if(requestValidation() && hasErrors.length == 0 && (hasOverride || Object.keys(data).length > 0)){
		 	var axiosRequest = await genAxios(method, url(), {
		 		'Authorization': `Bearer ${storedUser}`
		 	}, data);
			if(axiosRequest){
		 		setResult(true);
				setResultData(axiosRequest.data);
		 		close();
		    };
		}
	}

    const actions = [ 
        buttonMap(close, cancelSX, closeText, disabled, 'cancel'), 
        buttonMap(request, acceptSX, processText, disabled, 'submit')
    ];

	const modalData = ({ 
        actions,
		description,
		title,
		formControls,
		state: modalOpen,
		toggle: open,
		validation,
		selected,
		formData, 
		setFormData,
		type,
		hasValidated,
		setHasErrors,
		hasErrors
	});
	
	const getModalComponent = () => {
		return (validation && validation() && <ModalComponent key={type} data={modalData}>
			<Box>
				<form onSubmit={e => e.preventDefault()} noValidate autoComplete='off'>
				{
					<DialogComponent key={type} data={{...modalData, defaultValue: selected, hasValidated, setHasErrors }}></DialogComponent>
				}
				</form>
			</Box>
		</ModalComponent>)
	};

	return {
		selected,
		setSelected,
		modalData,
		modalOpen,
		result,
		open,
		close,
		getModalComponent,
		resultData,
	}
}

export default ModalService;