import React, {
	useState,
	useEffect,
	useContext
} from 'react';

import {
    TextField
} from '@mui/material';

import { collectFormData } from './form-control-helper-component';

const getProperty = (obj, keys) => {
    return keys.reduce((f, i)=> {
        if(f[i]){
            return f[i];
        }
        return obj;
    }, obj);
}

const FormControlFileComponent = (props) => {
    const {
        data:{
            disabled,
            label,
            placeholder,
            defaultValue,
            type = undefined,
            name
        }
    } = props;

    const fieldName = (name ?? label).toLowerCase()?.split('.');
	const [selectedFile, setSelectedFile] = useState(null);

    const handleFileSelect = (event) => setSelectedFile(event.target.files[0]);

    useEffect(() => {
        if(selectedFile){
		    collectFormData(props, fieldName, selectedFile);
        }
	}, selectedFile);

    return (
        <React.Fragment>
             <TextField
                    disabled={disabled}
                    required
                    label={label}
                    defaultValue={defaultValue && defaultValue && getProperty(defaultValue, fieldName)}
                    onChange={(e) => handleFileSelect(e) }
                    fullWidth
                    placeholder={placeholder}
                    type={type}
                    variant="outlined" />
        </React.Fragment>
    )
};


export default FormControlFileComponent;