
import React from 'react';
import {
    Box,
	Tabs,
	Tab
} from '@mui/material';

import { boxSX, tabListSX, tabSX } from './style-constant';
import { container } from '../style-constants';

 const TabList = (props) => {
    const {
        value,
        setTab,
        children,
        groupings = undefined,
        disableAll = false,
        component = undefined
    } = props;

    const {
        width,
    } = container;

    const tabs = groupings?.map((f) => <Tab key={f.name} label={f.name} value={f.name} sx={tabSX} />);

    return (
        groupings && value && <React.Fragment>
            <Box sx={{
                    width, 
                }}>

                <Box sx={boxSX}>
                    <Tabs
                        value={value}
                        onChange={ (_, f) => setTab(f) }
                        variant= "scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable"
                        sx={tabListSX}>

                        { !disableAll && <Tab label="All" value='All' sx={tabSX} /> }
                        {
                            tabs
                        }
                        {children}
                    </Tabs>
                    {component}
                </Box>
            </Box>
        </React.Fragment>
    )
}

export default TabList;