import React from 'react';
import _ from 'lodash'

import {
  TextField,
  Autocomplete,
} from '@mui/material';

const MultiSelectField = (props) => {
  const {
    data:{
        label,
        placeholder,
        perms,
        fieldName = 'permission',
        setFormData,
        formData,
    }
  } = props

  const linkingData = React.useMemo(() => { return formData[fieldName]; }, [formData, fieldName]);
  React.useEffect(() => {
    if (linkingData) {
        setFormData(prev => {
            return {
                ...prev,
                [fieldName]: linkingData.sort((a, b) => -b.name.localeCompare(a.name)),
                [`${_.lowerFirst(fieldName)}Ids`]: linkingData.map((obj) => obj.id)
            }
        })
    }
  }, [fieldName, setFormData])

  return (
    <Autocomplete
      disableCloseOnSelect={true}
      multiple
      options={ perms }
      value={ formData[fieldName] || [] }
      onChange={(_, f) => { setFormData(prev => ({ ...prev, [fieldName]: f })) }}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => { return option.id === value.id }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={ (!linkingData || linkingData.length > 0) && placeholder || ""}
          InputProps={{
            ...params.InputProps,
          }}
        />
      )}
    />
  );
}


export default MultiSelectField

