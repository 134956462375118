import React, {
	useState,
	useEffect,
	useContext
} from 'react';

import {
	useNavigate
} from 'react-router-dom';

import {
	Box,
	IconButton,
	Typography,
	TableCell,
	TableRow,
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';

import { CLIENTS } from '../config/api';
import Footer from '../components/footer';
import ModalComponent from '../components/common/global-component/modal/modal-component';
import DialogComponent from '../components/common/global-component/dialog/dialog-component';
import ModalService from '../services/modal-service';
import { AppPermissionsContext } from '../components/common/app-context/app-permission-context';
import { getClients} from '../services/api-services';
import TableContent from '../components/common/pages/table-content-components';

const screen = {
	type: 'Clients',
	heading: {
		title: 'Client Management',
		description: 'All aspects related to clients can be managed from this page.'
	},
	search: {
		type:'text',
		placeholder:'Search by client.',
		fullWidth:true,
		size:'small'
	},
	tableHead: [
		'Name',
		'Description',
		''
	]
}

const Clients = () => {
    const [objects, setObjects] = useState([]);
	const { storedUser, permissions } = useContext(AppPermissionsContext);
	const navigate = useNavigate();

	const loadData = async () => {
		getClients(storedUser)
			.then(response =>{
				setObjects(response.data);
			});
	}

	const formControls = [
		{
			label: "Name",
			placeholder: "Your client name, please",
			helperText: "Client name is required",
			required: true,
			validation: (obj) => true
		},
		{
			label: "Description",
			placeholder: "Describe your company, please",
			helperText: "Client description is required",
			required: true,
			validation: (obj) => true
		}
	]

	const createModal = {
		form: {
			description: 'Fill in all the fields below to create a new client.',
			formControls,
			processText: 'Add new client',
			title: 'Add New Client',
			validation: () => true,
			type: 'create',
		},
		request: {
			method: 'POST',
			requestValidation: () => true,
			url: () => `${CLIENTS}/create`,
		}
	}

	const editModal = {
		form: {
			description: 'Fill in all the fields below to create a new client.',
			formControls,
			processText: 'Edit existing client',
			title: 'Edit Existing Client',
			validation: () => true,
			type: 'edit'
		},
		request: {
			method: 'PATCH',
			requestValidation: () => modals.edit.modalData.selected,
			url: () => `${CLIENTS}/${modals.edit.modalData.selected.id}`,
		}
	}

	const deleteModal = {
		form: {
			description: 'Are you sure, you know what you doing?',
			formControls: [
				{
					label: "Name",
					type: 'readonly',
					disabled: true,
				},
				{
					label: "Description",
					type: 'readonly',
					disabled: true,
				}
			],
			processText: 'Delete existing client',
			title: 'Delete Existing Client',
			validation: () => true,
			type: 'delete'
		},
		request: {
			method: 'DELETE',
			requestValidation: () => modals.delete.modalData.selected.id,
			url: () => `${CLIENTS}/${modals.delete.modalData.selected.id}`,
		}
	}

	const modals = {
		create: ModalService(createModal),
		edit: ModalService(editModal),
		delete: ModalService(deleteModal),
	}

	useEffect(() => {

		loadData();

	}, [navigate, modals.delete.result, modals.edit.result, modals.create.result]);

	const actions = [
		{
			icon: <AddIcon />,
			name: 'Add New Client',
			callback: modals.create.open
		}
	];

	const filterOptions = (obj, _, searchValue) => {
		return obj.name.toLowerCase().includes(searchValue.toLowerCase()) ||
		obj.description.toLowerCase().includes(searchValue.toLowerCase())
	}

	const tableBodyMapper = (f) => (
		<TableRow key={`${f.id}_row`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
			<TableCell key={`${f.id}_name`}>{f.name}</TableCell>
			<TableCell key={`${f.id}_description`}>{f.description}</TableCell>

			<TableCell key={`${f.id}_actions`}>
				<Typography key={`${f.id}_typo`}>
					{ permissions.Clients.update && <IconButton key={`${f.id}_edit`}
						onClick={(e) => modals.edit.open(e, f)}>
						<EditTwoToneIcon />
					</IconButton>
					}
					{ permissions.Clients.delete && <IconButton key={`${f.id}_delete`}
						onClick={(e) => modals.delete.open(e, f)}>
						<DeleteTwoToneIcon />
					</IconButton>
					}
				</Typography>
			</TableCell>
		</TableRow>
	);

	const getModalComponent = (modalData) => {
		const validation = modalData && modalData.validation && modalData.validation();
		return (validation && <ModalComponent key={modalData.type} data={modalData}>
			<Box>
				<form onSubmit={e => e.preventDefault()} noValidate autoComplete='off'>
					{
						<DialogComponent key={modalData.type} data={{...modalData, defaultValue: modalData.selected, 
							hasValidated: modalData.hasValidated, setHasErrors: modalData.setHasErrors }}></DialogComponent>
					}
				</form>
			</Box>
		</ModalComponent>
	)};

	return (
		<React.Fragment>
			<TableContent data={{
				objects, 
				body: tableBodyMapper,
				filterOptions,
				screen
			}}>
				<Footer actions={ permissions.Users.create && actions} />
				{
					[
						getModalComponent(modals.create.modalData), 
						getModalComponent(modals.edit.modalData),
						getModalComponent(modals.delete.modalData)
					]
				}
			</TableContent>
		</React.Fragment>
	);
}

export default Clients;